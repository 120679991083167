<template>
  <div class="form">
    <div class="flex  items-center bg-blue-700 py-2">
      <span class="w-2/5">
        <span class="px-2 bg-white ml-2
         rounded-full"
          :class="{'text-red-800': useDiaryStore().is_expired(useDiaryStore().diary?.valid_until_date), 
            'text-green-500': !useDiaryStore().is_expired(useDiaryStore().diary?.valid_until_date) }">
          <i v-if="!useDiaryStore().is_expired(useDiaryStore().diary?.valid_until_date)" class="fa-solid fa-clock mr-1 text-14px"></i>
          <i v-else class="fa-solid fa-hourglass-end mr-1 text-14px"></i>
          <span>
            {{ useDiaryStore().is_expired(useDiaryStore().diary?.valid_until_date) ? 
              formatDate(useDiaryStore().diary?.valid_until_date)
              : useDiaryStore().countdown }}
          </span>
        </span>
        <span v-if="useDiaryStore().is_expired(useDiaryStore().diary?.valid_until_date) && useDiaryStore().diary.status_id != 7"
          @click="useDiaryStore().reactivate_diary()"
          class="rounded-full bg-white text-red-800 cursor-pointer ml-2 px-1">
          Riaktivizo
        </span>
      </span>
      <h1 class=" text-center flex-grow">
        DITAR
        <span class="font-thin uppercase">{{ useDiaryStore().diary?.status }}</span>
        <span v-if="!useDiaryStore().diary?.id" class="text-xs text-gray-400">
          <i class="fa fa-spinner fa-spin text-yellow-300"></i> 
        </span>
      </h1>
      <span class="w-2/5 text-right">
        <span class="text-blue-700  px-4 bg-white mr-2 font-bold rounded-full w-1/3">
          {{ useDiaryStore().diary?.id }}
        </span>
        <small class="p-2 text-gray-200">{{ useDiaryStore().diary?.code }}</small>
      </span>
    </div>

    <!-- Pending discount header notification -->
    <div v-if="useDiaryStore().diary?.status_id == 6" class="flex bg-amber-400 mt-2 items-center justify-between">
      <div class="px-3">
        <i class="fa-solid fa-triangle-exclamation"></i>
      </div>
      <div class="text-14px uppercase text-center" style="flex-grow:1;">
        Kerkese per zbritje <br> 
        <i class="fa-solid fa-user mr-1"></i> {{useDiaryStore().diary.discount_request[0].requested_by_name}} 
        <i class="fa-regular fa-calendar-days ml-3 mr-1"></i> {{formatDate(useDiaryStore().diary.discount_request[0].created_at)}}
        <i class="fa-regular fa-circle-user ml-3 mr-1"></i> {{useDiaryStore().diary.discount_request[0].assigned_to_name}} 
      </div>
      <div v-if="am_allowed('diary_roles.can_see_request_discount')"
        @click="open_discount_template(useDiaryStore().diary?.discount_request[useDiaryStore().diary?.discount_request.length - 1].guid)" 
        class="px-3 cursor-pointer">
        <i class="fa-solid fa-up-right-from-square"></i>
      </div>
    </div>
    <!-- Waiting for customer -->
    <div v-if="useDiaryStore().diary?.requested_by_customer && useDiaryStore().diary?.status_id != 5 && useDiaryStore().diary?.status_id != 7" class="flex bg-amber-400 mt-2 items-center justify-between text-14px">

      <div class="px-3">
        <i class="fa-solid fa-triangle-exclamation"></i>
      </div>
      <div class="text-12px uppercase text-center flex flex-col" style="flex-grow:1;">
        <span> Ne pritje te konfirmimit nga klienti </span>
        <span>
          <i class="fa-regular fa-calendar-days ml-3 mr-1"></i> {{formatDate(useDiaryStore().diary.requested_by_customer)}}
        </span>
        <span v-if="useDiaryStore().diary.requested_text">
          <i class="fa-regular fa-comment ml-1"></i> {{useDiaryStore().diary.requested_text}}
        </span>
      </div>
      <div></div>
    </div>

    <div v-if="!useDiaryStore().diary?.customer"
        class="border rounded border-green-500 bg-green-200 flex mt-2">
        <div class="p-3">
          <i class="fa-solid fa-triangle-exclamation text-green-800"></i>
        </div>
        <div class="flex items-center">
          <span>
            Ditar i ri. Zgjidh klientin.
          </span>
        </div>
    </div>
    <div class="heading-block flex">
      <!-- BLOCK: Left side -->
      <div class="flex flex-col w-2/3 p-2 left-block">
        <div class="flex gap-1 text-xs">
          <div class="flex justify-start items-center gap-1">
            <label class="text-gray-700" for="data">Data</label>
          </div>
          <div class="flex justify-start ml-auto items-center gap-1">
            <i class="fa fa-calendar"></i>
            {{ new Date(useDiaryStore().diary?.created_at).toLocaleDateString() }}
            <span class="text-gray-400">
              <i class="fa fa-clock"></i>
              {{ new Date(useDiaryStore().diary?.created_at).toLocaleTimeString() }}
            </span>
          </div>
        </div>
        <div class="flex justify-start items-center gap-1 mt-1">
          <label class="text-gray-700" for="perdoruesi">Perdoruesi</label>
          <div class="border border-gray-300 w-full p-1 pr-5 text-right text-sm">
            {{ useDiaryStore().diary?.name }}
          </div>
        </div>
        <div v-if="useDiaryStore().diary?.entity_id" class="flex justify-start items-center gap-1 mt-1 text-sm">
          <label class="text-gray-700" for="kanalet">Kanalet e Shitjes</label>
          <!-- <input class="border border-gray-300 p-1" type="text" name="kanalet" id="kanalet"
            placeholder="Kanalet e Shitjes"> -->
            <select v-model="useDiaryStore().diary.sales_channel"
              @change="updateDiary(selectedCustomer)"
              class="border border-slate-300 rounded py-1 text-right">
              <option :value="0">Zgjidh kanalin e shitjes</option>
              <option :value="1">Dyqani</option>
              <option :value="2">Web</option>
              <option :value="3">Agjenti</option>
            </select>
        </div>
        <div class="flex justify-start items-center gap-1  mt-1">
          <label class="text-gray-700" for="pos">Pika e Shitjes</label>
          <!-- <input class="border border-gray-300 p-1" type="text" name="pos" id="pos" placeholder="pos e Shitjes"> -->
          <select v-if="useDiaryStore().products.length == 0" class="border border-gray-300 p-1 text-right text-sm" name="pos" id="pos" v-model="form.pos" @change="savePosChange()">
            <option v-for="pos in useDiaryStore().assigned_poses" :key="pos.guid" :value="pos.guid">{{ pos.name }}
            </option>
          </select>
          <span v-else class="text-right flex-grow border rounded border-gray-300 p-1">
            {{useDiaryStore().diary.pos_name}}
          </span>
        </div>
        <br>
        <!-- BLOCK: Customer -->
        <div class="mt-1 flex justify-end customers-badges-head">
          <transition name="fade">
            <div class="badge bg-blue-50" v-if="selectedCustomer?.id">
              <span v-if="selectedCustomer?.type_id_name">
                {{ selectedCustomer?.type_id_name }}
              </span>
              <span v-else class="text-orange-600" style="font-weight: 500;">
                {{ '* PA KATEGORIZUAR *' }}
              </span>
            </div>
          </transition>
        </div>
        <div class="flex justify-start items-center mt-1 input-wrapper">
          <label class="text-gray-700" for="emri">Emri I Klientit</label>
          <div class="relative w-full flex-grow" v-click-outside="outOfCustomersList()">
            <div v-if=" useDiaryStore().diary?.status_id == 7 || useDiaryStore().diary?.status_id == 5" class="border border-slate-400 text-right pr-2 text-14px">
              {{searchCustomerQuery}}
            </div>
            <div v-else>
              <!-- Search Input -->
              <div class="relative">
                <!-- add customer shows only in mobile -->
                <span class="absolute top-1-2 left-2 create-customer-mobile">
                  <i class="fa fa-plus-circle cursor-pointer fa-xs" @click="createCustomer()"></i>
                </span>
                <span class="absolute top-1-2 left-8" v-if="selectedCustomer?.id">
                  <i class="fa fa-user-pen cursor-pointer fa-xs" @click="editCustomer()"></i>
                </span>
                <input id="search-customer" type="text" placeholder="Emri I Klientit..."
                  class="border border-gray-300 p-1 w-full" autocomplete="off" :class="[
                    !form.customer && searchCustomerQuery != '' ? 'invalid' : '',
                    form.customer && searchCustomerQuery != '' ? 'valid' : '',
                  ]" v-model="searchCustomerQuery" @blur="resetCustomer" @keydown.esc="filteredCustomerOptions = []"
                  @input="filterCustomerOptions">
                <span class="absolute top-0 bottom-0 right-2 h-full flex items-center">
                  <i class="fa fa-search" v-if="searchCustomerQuery == ''"></i>
                  <i class="fa fa-times cursor-pointer" v-if="searchCustomerQuery != ''"
                    @click="searchCustomerQuery = ''; filterCustomerOptions(); focusCustomerSearchInput();"></i>
                </span>
              </div>
              <!-- BLOCK: Customers Dropdown List -->
              <ul v-if="filteredCustomerOptions.length > 0 && searchCustomerQuery != ''" role="listbox"
                class="absolute w-full m-0 mt-2 bg-white rounded border">
                <li v-for="option in filteredCustomerOptions.slice(0, 10)" :key="option" role="option" tabindex="0"
                  class="cursor-pointer hover:bg-gray-200 p-1 flex flex-col border-b text-right" @keydown.enter="selectOption(option, true)"
                  @click="selectOption(option, true)">
                  <span class="text-xs text-gray-400">{{ option.type_id_name }}</span>
                  <span class="flex ml-auto" :class="[option?.guid == selectedCustomer?.guid ? 'text-blue-700' : '']">
                    {{ option.name }}
                    <!-- <span class="text-xs mr-auto rounded-full w-1 h-1"></span> -->
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div class="create-customer-mobile justify-end">
          <button class="text-blue-500 hover:text-blue-900 p-1 text-10px" @click.prevent="createCustomer()">
            Shto Klient te ri
          </button>
        </div> -->
        <div class="flex justify-start items-center mt-1 input-wrapper">
          <label class="text-gray-700" for="nr">
            Nr.kontakti
          </label>
          <input class="border border-gray-300 p-1" 
            :class="[
              (!form.contact_nr && selectedCustomer?.id) ? 'invalid' : '',
              form.contact_nr && selectedCustomer?.id ? 'valid' : '',
            ]" type="text" name="nr" id="nr"
            :placeholder="!form.contact_nr && selectedCustomer?.id ? '&#xf071;' : 'Nr.kontakti'"
            v-model="form.contact_nr" @keydown.enter="updateDiary(selectedCustomer)">
        </div>
        <div class="flex justify-start items-center mt-1 input-wrapper">
          <label class="text-gray-700" for="adresa">Adresa</label>
          <input class="border border-gray-300 p-1" type="text" name="adresa" id="adresa" :class="[
            !form.location && selectedCustomer?.id ? 'invalid' : '',
            form.location && selectedCustomer?.id ? 'valid' : ''
          ]" v-model="form.location" :placeholder="!form.contact_nr && selectedCustomer?.id ? '&#xf071;' : 'Adresa'" 
            @keydown.enter="updateDiary(selectedCustomer)"
          >
        </div>
        <div class="flex justify-start items-center mt-1 input-wrapper">
          <label class="text-gray-700" for="adresa">Tipi i shitjes</label>
          <select class="border border-gray-300 p-1 text-right" type="text" name="adresa" id="adresa" :class="[
            !form.sales_type && selectedCustomer?.id ? 'invalid' : '',
            form.sales_type && selectedCustomer?.id ? 'valid' : '']" 
            v-model="form.sales_type" 
            @change="updateDiary(selectedCustomer)" >
            <option :value="1"> Pakice </option>
            <option :value="2"> Shumice </option>
          </select>
        </div>
        <!-- Shows only when customer is selected -->
        <transition name="slide-right">
          <div v-if="selectedCustomer?.id" class="mt-2 flex justify-end">
            <span class="badge" :class="[form?.tipi_klientit == 'RI' ? 'bg-green-50' : '']">
              <i class="fa fa-user fa-xs text-gray-400"></i>
              <span>{{ customer_types[form?.tipi_klientit] }}</span>
            </span>

            <span class="badge" v-if="selectedCustomer.referred_by_name">
              <i class="fa fa-asterisk fa-xs text-gray-400"></i>
              <small>REFERUAR NGA</small>
              <span v-if="selectedCustomer.referred_by != 6">{{ selectedCustomer.referred_by_name }}</span>
              <span v-else>{{ selectedCustomer.other_referral }}</span>
            </span>
            <span class="badge" v-if="useDiaryStore().isSameDayAsToday(selectedCustomer.dob)">
              <i class="fa-regular fa-face-smile-beam"></i>
              {{ 'KA DITELINDJEN SOT' }}
            </span>
          </div>
        </transition>
      </div>
      <!-- BLOCK: Right side -->
      <div class="w-1/3 flex justify-center items-center right-block">
        <div class="historical-data">
          <div class="uppercase border-b ">Te dhena historike mbi Klientin</div>
          <div class="flex flex-col w-full flex-grow">
            <div class="flex px-1 text-xs text-slate-400">
              <small class="w-1/3 flex">
                <span class="w-1/3"> # </span>
                Krijuar me 
              </small>
              <small class="w-1/3 text-right"> Krijuar nga </small>
              <small class="w-1/3 text-right"> Operacioni </small>
            </div>
            <div class="overflow-y-auto border-b" style="max-height: 150px;">
              <div v-for="(prev_diary, index) in useDiaryStore().previews_diaries" :key="index" 
                @click="open_prev_diary(prev_diary.d_guid)"
                class="border-b hover:bg-sky-100 cursor-pointer mb-1">
                <div class="flex px-1">
                  <span class="w-1/3 flex">
                    <span class="w-1/3"> {{prev_diary.d_id}} </span>
                    {{new Date(prev_diary.d_created_at).toLocaleDateString()}}
                    <!-- {{moment(prev_diary.created_at).fromNow()}} -->
                  </span>
                  <span class="w-1/3 text-right">{{prev_diary.created_by_name}}</span>
                  <span class="w-1/3 text-right">
                    {{ prev_diary.status_name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class=" flex justify-between items-center text-10px w-full px-2">
            <button class="text-blue-500 hover:text-blue-900 text-white font-bold p-1 " @click.prevent="createCustomer()">
              Shto Klient te ri
            </button>
            
            <button class="text-blue-500 hover:text-blue-900 text-white font-bold p-1 ">
              Shto Karte besnikerie
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- BLOCK: products -->
    <div class="products mt-4 p-2">
      <div
        class="font-bold bg-orange-500 text-white w-full p-1 px-2 border-b border-orange-800 flex items-center justify-between">
        PRODUKTET
        <a v-if="useDiaryStore().diary?.customer && (useDiaryStore().diary?.status_id < 7 && useDiaryStore().diary?.status_id != 2 && useDiaryStore().diary?.status_id != 5) && (am_allowed('diary_roles.add_diary_product') || am_allowed('diary_roles.add_diary_service') || am_allowed('diary_roles.can_add_free_line_product'))" 
          href="#"
          @click.prevent="useDiaryStore().searchProductToggle()">
          <i class="fa fa-plus-circle float-right"></i>
        </a>
      </div>
      <div class="flex w-full overflow-x-scroll">
        <table class="table w-full table-products is-narrow">
          <thead>
            <tr>
              <th class="text-xs">#</th>
              <th class="text-xs">Emri</th>
              <th class="text-xs text-right" width="150px">Magazina</th>
              <th class="text-xs text-right">Sasia</th>
              <th class="text-xs text-right">Cm. Dyq. (pa_oferte)</th>
              <th class="text-xs text-right">Cmim Oferte</th>
              <th class="text-xs text-right">Vl.tot Para_Zbritjes</th>
              <th class="text-xs text-right">Zbritja</th>
              <th class="text-xs text-right">Vl.tot Pas_Zbritjes</th>
              <th class="text-xs text-right">Shtuar nga</th>
              <th class="text-xs text-center"><i class="fa-regular fa-square-check mx-3"></i></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, index) in useDiaryStore().products.filter(a => a.related_entity == 1)" :key="index"
              :class="{
                'bg-amber-100': product.entity_id == 'unknown',
                'bg-red-100': product.amount == 0
                }">
                <td>{{index + 1}}</td>
                <td>
                  <div class="flex flex-col">
                    <span :class="{'line-through': product.is_active == 2 }"> 
                      {{product.entity_id == 'unknown' ? product.free_line_description : product.product_name}}
                    </span>
                    <span class="text-slate-400 text-10px">{{product.product_code}}</span>
                    <div class="flex flex-col">
                      <div class="flex">
                        <!-- Product transport -->
                        <div class="border rounded border-slate-300 text-10px pl-1 mr-1 mt-1 w-28"
                          :class="[product.transport_service ? 'bg-green-50' : '']"
                          style="height: max-content; min-width: ;">
                          <span v-if="!product.transport_service"
                            @click="start_toggle_service_modal('transport', product)"
                            class="uppercase pr-1 cursor-pointer w-full flex items-center text-slate-500">
                            <i class="fa-solid fa-truck mr-1"></i>Transport
                          </span>
                          <div v-else class="flex flex-col text-10px">
                            <div class="flex">
                              <span class="mr-2 text-8px">{{product.transport_service.name}}</span>
                              <span v-if=" product.is_active < 2"
                                @click="useDiaryStore().delete_related_service(product.transport_service)"
                                class="ml-auto text-slate-400 cursor-pointer flex-items-top text-8px"
                                style="margin-right:2px;">
                                  <i class="fa-solid fa-times-circle "></i>
                              </span>
                            </div>
                            <div class="flex pr-1 items-center">
                              <span class="text-8px text-slate-500">
                                {{product.transport_service.code}}
                              </span>
                              <span class="ml-auto flex justify-end">
                                <span class="ml-2">
                                  {{useDiaryStore().price_value_filter(product.transport_service.price)}}
                                </span>
                                <span class="text-slate-400 text-10px  ml-1">Leke</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- Product mountable -->
                        <div class="border rounded border-slate-300 text-10px pl-1 mr-1 mt-1 w-28"
                          :class="{'bg-green-50': product.mountable_service}"
                          style="height: max-content;">
                          <span v-if="!product.mountable_service"
                            @click="start_toggle_service_modal('mountable', product)"
                            class="text-slate-500 uppercase pr-1 cursor-pointer w-full flex items-center">
                            <i class="fa-solid fa-screwdriver-wrench mr-1"></i>Montim
                          </span>
                          <div v-else class="flex flex-col text-10px">
                            <div class="flex ">
                              <span class="mr-2 text-8px">{{product.mountable_service.name}}</span>
                              <span v-if=" product.is_active < 2"
                                @click="useDiaryStore().delete_related_service(product.mountable_service)"
                                class="ml-auto text-slate-400 cursor-pointer flex-items-top text-8px"
                                style="margin-right:2px;">
                                  <i class="fa-solid fa-times-circle "></i>
                              </span>
                            </div>
                            <div class="flex pr-1 items-center">
                              <span class="text-8px text-slate-500">
                                {{product.mountable_service.code}}
                              </span>
                              <span class="ml-auto flex justify-end">
                                <span class="ml-2">
                                  {{useDiaryStore().price_value_filter(product.mountable_service.price)}}
                                </span>
                                <span class="text-slate-400 text-10px  ml-1">Leke</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex">
                        <!-- Pickup methods - Termat e levrimit -->
                        <div v-if="am_allowed('diary_roles.can_see_pickup')" class="border rounded border-slate-300 text-10px pl-1 mr-1 mt-1 w-28 flex flex-col"
                          :class="{'bg-green-50' : product.has_pickup}"
                          style="height: max-content; min-width: ;">
                          <span class="flex">
                            <span 
                              @click="start_toggle_pickup_modal(product)"
                              class="text-slate-500 uppercase pr-1 cursor-pointer w-full flex items-center">
                              <i class="fa-solid fa-box mr-1"></i>Dorezim
                            </span>
                            <span v-if="product.has_pickup && 
                            am_allowed('diary_roles.can_edit_pickup') && (useDiaryStore().diary?.status_id != 5 && useDiaryStore().diary?.status_id != 7)"
                              @click="start_delete_pickup(product)"
                              class="ml-auto text-slate-400 cursor-pointer flex-items-top text-8px"
                              style="margin-right:2px;">
                                <i class="fa-solid fa-times-circle "></i>
                            </span>
                          </span>
                            
                          <span v-if="product.has_pickup">
                            <span v-if="product.pickup_service.pickup_pos_original && product.pickup_service.pickup_type_original < 3 ">
                              <i class="fa fa-store mr-2" title="Pika e shitjes"></i>
                              <i v-if="product.pickup_service.same_location == 1" class="fa-solid fa-location-dot mr-1" title="Ne te njejten vendndodhje"></i>
                              {{product.pickup_service.pickup_pos_name}} 
                            </span>
                            <span v-if="product.pickup_service.pickup_warehouse_original && product.pickup_service.pickup_type_original == 3">
                              <i class="fa fa-warehouse mr-2" title="Magazina"></i>
                              <i v-if="product.pickup_service.same_location == 1" class="fa-solid fa-location-dot mr-1" title="Ne te njejten vendndodhje"></i>
                              {{product.pickup_service.pickup_warehouse_name}} 
                            </span>
                          </span>
                        </div>
                        <!-- Reservation -->
                        <div class="border rounded border-slate-300 text-10px pl-1 mr-1 mt-1 w-28"
                          :class="{'bg-green-50' : product.reservation_service}"
                          style="height: max-content; min-width: ;">
                          <span v-if="!product.reservation_service"
                            @click="start_toggle_service_modal('reservation', product)"
                            class="text-slate-500 uppercase pr-1 cursor-pointer w-full flex items-center">
                            <i class="fa-solid fa-boxes-stacked mr-1"></i>Rezervim
                          </span>
                          <div v-else class="flex flex-col text-10px">
                            <div class="flex ">
                              <span class="mr-2 text-8px">{{product.reservation_service.name}}</span>
                              <span v-if=" product.is_active < 2"
                                @click="useDiaryStore().delete_related_service(product.reservation_service)"
                                class="ml-auto text-slate-400 cursor-pointer flex-items-top text-8px"
                                style="margin-right:2px;">
                                  <i class="fa-solid fa-times-circle "></i>
                              </span>
                            </div>
                            <div class="flex pr-1 items-center">
                              <span class="text-8px text-slate-500">
                                {{product.reservation_service.code}}
                              </span>
                              <span class="ml-auto flex justify-end">
                                <span class="ml-2">
                                  {{useDiaryStore().price_value_filter(product.reservation_service.price)}}
                                </span>
                                <span class="text-slate-400 text-10px  ml-1">Leke</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-right ">
                  <!-- <span class="text-10px"> {{product.from_warehouse_name}} </span> -->
                  <small class="text-gray-400">
                    <!-- <p class="text-xs"> Magazina </p> -->
                    <a v-if="useDiaryStore().diary?.status_id == 1 || useDiaryStore().diary?.status_id == 4"
                      @click.prevent="product.show_from_warehouses = true; useDiaryStore().getOtherWarehousesOf(product);"
                      href="" class="text-blue-500 uppercase" 
                      title="Ndrysho magazinen...">
                      <i class="fa-solid fa-warehouse fa-xs"></i>
                      {{ product.from_warehouse_name }}
                    </a>
                    <span v-else>
                      <i class="fa-solid fa-warehouse fa-xs"></i>
                      <span class="mx-2 text-gray-800">
                        {{ product.from_warehouse_name }}
                      </span>
                      <i class="fa-solid fa-check fa-xs text-green-500"></i>
                    </span>
                    <div class="flex items-center justify-between gap-2" v-if="product.show_from_warehouses">
                      <a v-if="product.loaded_other_warehouse" href=""
                        @click.prevent="product.show_from_warehouses = false; product.other_warehouses = []" title="Mbyll"
                        class="flex text-center">
                        <i class="fa-solid fa-times-circle  fa-xs"></i>
                      </a>
                      <span v-else>
                        <i class="fa-solid fa-spinner fa-spin fa-xs"></i>
                      </span>
                      <!-- BLOCK: other warehouse -->
                      <select v-if="product.other_warehouse.length > 0"
                        @change="useDiaryStore().save_warehouse_change(product)" class="select is-small"
                        v-model="product.from_other_warehouse_id">
                        <option value="">Ndrysho magazinen...</option>
                        <option v-for="warehouse in product.other_warehouse" :key="warehouse.warehouse_id"
                          :value="warehouse.warehouse_id">
                          {{ warehouse.name }} x{{ warehouse.amount }}
                        </option>
                      </select>
                      <span v-if="product.loaded_other_warehouse && product.other_warehouse.length == 0"
                        class="text-xs text-red-500 text-10px">
                        Nuk ka magazina te tjera
                      </span>
                    </div>
                  </small>
                </td>
                <td class="text-right">
                  <div v-if="useDiaryStore().diary?.status_id != 5">
                    <span v-if="!product.change_amount">
                      {{ useDiaryStore().amount_filter(product.original_amount) }}
                      <i v-if="useDiaryStore().diary?.status_id != 5 && useDiaryStore().diary?.status_id != 7" @click="useDiaryStore().toggle_edit_amount(product)" 
                        class="fa-solid fa-pencil ml-2 cursor-pointer"></i>
                    </span>
                    <div v-else class="flex items-center">
                      <!-- v-if="useDiaryStore().can_edit_amount(product)" -->
                      <input 
                        v-model="product.amount" 
                        @keyup.enter="start_save_item_amount(product)"
                        class="input is-small" 
                        type="text" style="width:80px;">
                        <i @click="useDiaryStore().toggle_edit_amount(product)" class="fa-solid fa-xmark ml-2"></i>
                    </div>
                  </div>
                </td>
                <td class="text-right">{{useDiaryStore().price_filter(product.price)}}</td>
                <td class="text-right"></td>
                <td class="text-right"></td>
                <td class="text-right">
                  {{ product.discounted_price > 0 ? useDiaryStore().price_filter(product.price - product.discounted_price) : ''}}
                </td>
                <td class="text-right font-semibold text-green-700">
                  {{product.discounted_price > 0 ? useDiaryStore().price_filter(product.discounted_price) : ''}}
                </td>
                <td class="text-right">
                  {{product.created_by_name ? product.created_by_name : null}}
                </td>
                <td class="text-right text-center" style="vertical-align: middle;">
                  <div v-if="(!product.is_active || product.is_active == 1) && product.entity_id != 'unknown' && product.amount > 0">
                    <div v-if="useDiaryStore().diary?.status_id != 5 && useDiaryStore().diary?.status_id != 7">
                      <span v-if="product.loading_is_active">
                        <i class="fa fa-spinner fa-spin"></i>
                      </span>
                      <a v-else 
                        @click.prevent="start_toggle_diary_product_active(product)" href="">
                        <i class="fa-regular fa-square-check text-14px" v-if="product.is_active"></i>
                        <i class="fa-regular fa-square text-14px" v-else></i>
                      </a>
                    </div>
                  </div>
                </td>
                <td class="text-right" style="vertical-align: middle;">
                  <a v-if="product.is_active < 2 && am_allowed('diary_roles.delete_diary_product') && (useDiaryStore().diary?.status_id != 5 && useDiaryStore().diary?.status_id != 7)" @click.prevent="start_remove_diary_product(product)" href="">
                    <i v-if="product.loading_delete" class="fa-solid fa-spinner fa-spin"></i>
                    <i v-else class="fa-solid fa-trash-can"></i>
                  </a>
                  <div v-else-if="product.is_active == 2 && useDiaryStore().diary?.status_id != 5" class="text-10px"
                    :class="{'text-green-500': product.process_completed, 'text-blue-700': !product.process_completed}">
                    I procesuar
                    <i class="fa-solid fa-gears ml-1"></i>
                  </div>
                </td>
            </tr>
          </tbody>
          <tfoot>
            <!-- totals -->
            <tr class="bg-gray-700 text-white">
              <th class="text-xs text-center"> </th>
              <th class="text-xs text-white" colspan="2">
                <span class="text-white"> 
                  <!-- TOTALI PRODUKTEVE  -->
                </span>
              </th>
              <th class="text-xs text-center"> </th>
              <th class="text-xs text-center"> </th>
              <th class="text-xs text-center"> </th>
              <th class="text-xs text-center"> </th>
              <th class="text-xs text-center"> </th>
              <th class="text-xs text-center"> </th>
              <th class="text-xs text-center"> </th>
              <th class="text-xs text-center"> </th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </div>
      <!-- Services table -->
      <div v-if="useDiaryStore().products.filter(a => a.related_entity == 2 && !a.parent_entity).length > 0" 
        class="font-bold bg-orange-500 text-white w-full p-1 px-2 border-b border-orange-800 flex items-center justify-between mt-2">
        SHERBIME
      </div>
      <div v-if="useDiaryStore().products.filter(a => a.related_entity == 2 && !a.parent_entity).length > 0" class="flex w-full overflow-x-scroll">
        <table class="table w-full table-products is-narrow">
          <thead>
            <tr>
              <th class="text-xs">Emri</th>
              <th class="text-right" >Cmimi</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, index) in useDiaryStore().products.filter(a => a.related_entity == 2  && !a.parent_entity)" :key="index"
              :class="{'bg-amber-50': product.entity_id == 'unknown'}">
              <td>
                <div class="flex flex-col">
                  {{product.service_name}}
                  <span class="text-8px text-slate-400">
                    {{product.service_code}}
                  </span>
                </div>
              </td>
              <td class="text-right">{{product.service_price}}</td>
              <td class="text-right" style="vertical-align: middle;">
                <a v-if="product.is_active < 2 && (useDiaryStore().diary?.status_id != 5 && useDiaryStore().diary?.status_id != 7)" @click.prevent="start_remove_diary_product(product)" href="">
                  <i v-if="product.loading_delete" class="fa-solid fa-spinner fa-spin"></i>
                  <i v-else class="fa-solid fa-trash-can"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <!-- totals -->
            <tr class="bg-gray-700 text-white">
              <th class="text-xs text-center"> </th>
              <th class="text-xs text-center"> </th>
              <th class="text-xs text-center"> </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <!-- BLOCK: totals 1 / 2 -->
    <div class="w-full px-2 mb-5">
      <div class="flex justify-between items-center border border-blue-500 p-1 font-bold mb-1">
        <span class="uppercase text-sm">Totali i produkteve para zbritjes</span>
        <span>{{useDiaryStore().price_filter(useDiaryStore().diary_totals.total_product)}}</span>
      </div>
      <div class="flex flex-col items-center border border-blue-500 px-1 pt-1 pb-0  mb-1">
        <div class="flex justify-between w-full text-14px">
          <span class="uppercase font-bold">Sherbime</span>
        </div>
        <div class="flex justify-between w-full text-14px">
          <span class="uppercase">Transport</span>
          <span>{{useDiaryStore().price_filter(useDiaryStore().diary_totals.transport_total)}}</span>
        </div>
        <div class="flex justify-between w-full text-14px">
          <span class="uppercase">Montim</span>
          <span>{{useDiaryStore().price_filter(useDiaryStore().diary_totals.mountable_service)}}</span>
        </div>
        <div class="flex justify-between w-full font-bold">
          <span class="uppercase ">Totali</span>
          <span>{{useDiaryStore().price_filter(useDiaryStore().diary_totals.services_total)}}</span>
        </div>
      </div>
      <div class="flex justify-between items-center border border-blue-500 p-1 font-bold mb-1">
        <span class="uppercase text-sm">Zbritja e miratuar</span>
        <span>{{useDiaryStore().price_filter(useDiaryStore().diary_totals.total_discounted)}}</span>
      </div>

      <div class="flex justify-between items-center border border-blue-500 p-1 font-bold mb-1 bg-gray-700 text-white">
        <span class="uppercase text-sm">Totali i ditarit pas zbritjes</span>
        <span>{{useDiaryStore().price_filter(useDiaryStore().diary_totals.total_after_discount)}}</span>
      </div>
    </div>

    <!-- BLOCK: totals 2 / 2 -->
    <div class="flex justify-between items-start gap-1 totals-2">
      <div class="w-4/5 pl-2">
        <div class="flex justify-between items-center border border-blue-500 p-1 font-bold mb-1">
          <span class="uppercase text-sm">Totali per tu paguar</span>
          <span>
            {{
              useDiaryStore().diary_totals.total_after_discount ? useDiaryStore().price_filter(useDiaryStore().diary_totals.total_after_discount)
              : useDiaryStore().price_filter(useDiaryStore().diary_totals.total)
            }}
          </span>
        </div>

        <div class="flex justify-between items-center border border-blue-500 p-1 font-bold mb-1">
          <span class="uppercase text-sm">Totali i paguar</span>
          <span>0</span>
        </div>

        <div class="flex justify-between items-center border border-blue-500 p-1 font-bold mb-1">
          <span class="uppercase text-sm">Limiti kreditor i paramiratuar</span>
          <span>0</span>
        </div>

        <div class="flex justify-between items-center border border-blue-500 p-1 font-bold mb-1">
          <span class="uppercase text-sm">Gjendje e lire nga limiti - per perdorim</span>
          <span>0</span>
        </div>
      </div>
      <div class="sidebar border-l border-r border-b text-sm flex-1 border-green-800 mr-2">
        <span class="block bg-black text-green-300 p-1 mb-0 font-bold text-center uppercase">Llogaria e Klientit</span>
        <div class="p-2 bg-green-100">
          <div class="flex justify-between items-center border-b">
            <div>Gjendja Korente</div>
            <div>0</div>
          </div>
          <div class="flex justify-between items-center border-b">
            <div>Vlera ne Proces</div>
            <div>{{useDiaryStore().price_filter(useDiaryStore().diary_totals.processed_total)}}</div>
          </div>
          <div class="flex justify-between items-center border-b">
            <div>Gjendja e vlefshme (e lire)</div>
            <div>0</div>
          </div>
        </div>
      </div>
    </div>

    <!-- BLOCK: Comments -->
    <div class="comments mt-4 p-2 flex flex-col">
      <div
        class="font-bold bg-gray-700 text-sm text-white w-full p-1 px-2 border-b border-orange-800 flex items-center justify-between">
        KOMENTET
      </div>
      <!-- add new comment -->
      <div class="flex justify-between items-center border border-green-500 p-1 font-bold mb-1 gap-2">
        <span class="uppercase text-sm font-light text-green-800">{{ useDiaryStore().current_user.name }}</span>
        <input class="border bg-green-100 p-1 flex-1" type="text" name="comment" id="comment" placeholder="Shto koment..."
          v-model="commentText" style="border: 1px solid green; color: darkgreen; text-align: left">
        <button class="btn btn-sm btn-primary hover:text-blue-500 w-12 hover:border-black" @click="addComment()">
          <i class="fa fa-plus"></i>
        </button>
      </div>
      <!-- comments list -->
      <div class="flex flex-col border border-blue-500 border-opacity-25 p-1 mb-1 gap-1 w-3/4 "
        :class="[useUserStore().user.id == comment.created_by ? 'ml-auto chat-container-right' : 'chat-container-left']"
        v-for="comment in useDiaryStore().comments" :key="comment.created_at">

        <div class="text-12px">
          <i class="fa fa-user mr-1 fa-xs text-gray-400"></i>
          {{ comment.created_by_name }}
        </div>
        <div class="flex w-full items-end">
          <div class="grow">
            {{ comment.comment }}
          </div>
          <div class="text-xs block text-blue-600 font-light ml-auto">
            <i class="fa fa-clock mr-1 fa-xs text-gray-400"></i> {{ moment(comment.created_at).fromNow() }}
          </div>
        </div>

        <!-- <span class="uppercase text-sm font-light w-2/12">
          <i class="fa fa-user mr-1 fa-xs text-gray-400"></i>
          {{ comment.created_by_name }}
          <span class="text-xs block text-blue-600 font-light" :title="comment.created_at">
            <i class="fa fa-clock mr-1 fa-xs text-gray-400"></i>
            {{ moment(comment.created_at).fromNow() }}
          </span>
        </span>
        <span class="w-10/12">{{ comment.comment }}</span> -->

      </div>
    </div>

    <!-- BLOCK: Create Customer Modal Form -->
    <div
      class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-top justify-center create-customer"
      style="z-index:99;"
      v-if="toggleCreateCustomer" @click.self="toggleCreateCustomer = false">
      <DaForm v-if="toggleCreateCustomer"
        class="bg-white p-1 rounded-md shadow-lg text-left mt-3" 
        @submitted="submitCustomer"
        style="max-height: 80vh; overflow: scroll;"
        :form="useDiaryStore().customer_create_form">
      </DaForm>
    </div>

    <!-- BLOCK: Product service modal -->
    <div class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-start justify-center"
      v-if="useDiaryStore().show_service_modal" @click.self="start_toggle_service_modal('', null)">
      <div class="p-2 bg-white mt-20 flex-col products-search">
        <div>
          {{useDiaryStore().seleced_service_type == 'transport' ? 'Transport per ' :
            useDiaryStore().seleced_service_type == 'mountable' ? 'Montim per per ' : null }}
          <span class="font-semibold">
            {{useDiaryStore().service_selected_product.product_name}}
          </span>
        </div>
          <!-- Search for service -->
        <div>
          <div class="mt-2"
            style="max-height:80vh; overflow-y:auto;">
            <!-- Search input -->
            <div class="w-full">
              <!-- Add service -->
              <input type="text" placeholder="Kerko sherbim..." 
                class="border border-gray-300 p-1  text-xs"
                @keydown="useDiaryStore().search_service_diary()" 
                v-model="useDiaryStore().service_search_val">
              <span class="absolute top-0 bottom-0 right-4 h-full flex items-center">
                <i class="fa fa-search fa-xs"></i>
              </span>
            </div>
            <!-- Search results -->
            <div v-for="(key, idx) in Object.keys(useDiaryStore().service_search_results)" :key="idx"
              class="flex flex-col text-14px">
              <div class="bg-amber-100 text-10px px-1">{{key}}</div>
              <div v-for="(service, index) in useDiaryStore().service_search_results[key]" :key="index"
                :class="{'bg-slate-100': service.expanded}"
                class="flex flex-col px-2 py-1 border-b border-slate-200">
                <div class="flex">
                  <div class="flex flex-col">
                    <span>{{service.name}}</span>
                    <span class="text-10px">{{service.description}}</span>
                  </div>
                  <div class="ml-auto flex flex-col">
                    <span class="text-right">
                      <span>
                        {{useDiaryStore().price_value_filter(service.price)}} 
                        <span class="text-slate-400 text-10px">Leke</span>
                      </span>
                    </span>
                    <span @click="add_diary_service_start(service)"
                      class="w-full bg-green-400 border rounded text-white px-2 text-14px
                        text-center border-green-600 hover:bg-green-500 cursor-pointer ml-auto"
                      style="width:fit-content;">
                      <i class="fa-solid fa-plus"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Edit service -->
        </div>
      </div>
    </div>

    <div class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-start justify-center text-14px"
      v-if="useDiaryStore().show_pickup_modal" @click.self="start_toggle_pickup_modal(null)">
      <div class="p-2 bg-white mt-20 flex-col products-search w-1/3">
        <div>
          Dorezim per: 
          <span class="font-semibold">
            {{useDiaryStore().pickup_selected_product.product_name}}
          </span>
        </div>
          <!-- Search for service -->
        <div>
          <div class="mt-2"
            style="max-height:80vh; overflow-y:auto;">
            <div class="flex justify-center justify-around mb-2">
              <label class="flex flex-col text-center items-center mr-4">
                <input :disabled="!am_allowed('diary_roles.can_edit_pickup') ? true : false"
                  type="radio"
                  v-model="useDiaryStore().pickup_selected_product.pickup_service.pickup_type"
                  :value="1"
                  class=""
                />
                <span> Ne piken e shitjes </span>
              </label>
              <label class="flex flex-col text-center items-center mr-4">
                <input :disabled="!am_allowed('diary_roles.can_edit_pickup') ? true : false"
                  type="radio"
                  v-model="useDiaryStore().pickup_selected_product.pickup_service.pickup_type"
                  :value="2"
                  class=""
                />
                <span> Ne nje nga dyqanet </span>
              </label>
              <label class="flex flex-col text-center items-center">
                <input :disabled="!am_allowed('diary_roles.can_edit_pickup') ? true : false"
                  type="radio"
                  v-model="useDiaryStore().pickup_selected_product.pickup_service.pickup_type"
                  :value="3"
                  class=""
                />
                <span> Ne nje nga magazinat  </span>
              </label>
            </div>
            <div class="flex gap-2 text-14px">
              <div v-if="useDiaryStore().pickup_selected_product.pickup_service.pickup_type == 2" 
              class="w-1/2 flex text-center text-12px">
                <div class="flex w-full">
                  <select v-model="useDiaryStore().pickup_selected_product.pickup_service.pickup_pos" 
                    :disabled="!am_allowed('diary_roles.can_edit_pickup') ? true : false"
                    class="border rounded w-full" 
                    :class="{'border border-red-500 rounded' : !useDiaryStore().pickup_selected_product.pickup_service.pickup_pos}">
                    <option :value="0">Zgjidh POS</option>
                    <option v-for="(pos, index) in useDiaryStore().pickup_pos_list" :key="index" :value="pos.guid">{{pos.name}}</option>
                  </select>
                </div>
              </div>
              <div v-if="useDiaryStore().pickup_selected_product.pickup_service.pickup_type == 3" 
              class="w-1/2 flex text-center text-12px">
                <div class="flex w-full">
                  <select v-model="useDiaryStore().pickup_selected_product.pickup_service.pickup_warehouse" 
                    :disabled="!am_allowed('diary_roles.can_edit_pickup') ? true : false"
                    class="border rounded w-full" 
                    :class="{'border border-red-500 rounded' : !useDiaryStore().pickup_selected_product.pickup_service.pickup_warehouse}">
                    <option :value="0">Zgjidh magazine</option>
                    <option v-for="(warehouse, index) in useDiaryStore().pickup_warehouse_list" :key="index" :value="warehouse.guid">{{warehouse.name}}</option>
                  </select>
                </div>
              </div>

              <div :class="[
                useDiaryStore().pickup_selected_product.pickup_service.pickup_type == 1 ? 'w-full' : 'w-1/2'
              ]" class="items-top">
                <Datepicker
                  v-model="useDiaryStore().pickup_selected_product.pickup_service.pickup_date"
                  :format="'dd/MM/yyyy HH:mm'"
                  :disabled="!am_allowed('diary_roles.can_edit_pickup') ? true : false"
                  class="datepicker-wrap-pickup p-0 "
                  :class="{'border border-red-500 rounded' : !useDiaryStore().pickup_selected_product.pickup_service.pickup_date}"
                />
              </div>
            </div>
            <div class="flex flex-col">
              <span>Shenim</span>
              <textarea v-model="useDiaryStore().pickup_selected_product.pickup_service.pickup_text" 
                :disabled="!am_allowed('diary_roles.can_edit_pickup') ? true : false"
                class="border rounded px-2" rows="4">
              </textarea>
            </div>
            <div v-if="useDiaryStore().pickup_selected_product.pickup_service.pickup_type > 0 && 
              am_allowed('diary_roles.can_edit_pickup')"
              class="flex gap-2 mt-2">
              <a v-if="useDiaryStore().pickup_selected_product.has_pickup"
                @click.prevent="start_delete_pickup(useDiaryStore().pickup_selected_product)" 
                class="bg-red-200 border-red-900 border rounded text-center text-white"
                :class="[useDiaryStore().pickup_selected_product.has_pickup ? 'w-1/2' : 'w-full']"
                href="">
                <i class="fa-solid fa-trash-can text-red-900"></i>
              </a>
              <a v-if="useDiaryStore().can_confirm_pickup"
                @click.prevent="start_save_pickup_location()" 
                class="bg-green-200 border-green-800 border rounded text-center text-white"
                :class="[useDiaryStore().pickup_selected_product.has_pickup ? 'w-1/2' : 'w-full']"
                href="">
                <i class="fa-solid fa-check text-green-800"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- BLOCK: Search Product Modal Form -->
    <div class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-start justify-center"
      v-if="useDiaryStore().toggleAddProduct" @click.self="useDiaryStore().toggleAddProduct = false">
        <!-- :style="useDiaryStore().search_item_mode ? {'overflow':'auto','max-height':'65%'} : {}" -->
      <div class="p-2 bg-white mt-20 flex-col products-search" 
        style="max-height: 63%; overflow: auto; min-height: 35%; min-width:80%;">
        <!-- Search input -->
        <div class="flex items-top relative">
          <div class="mr-2 cursor-pointer">
            <div class="relative">
              <span @click="useDiaryStore().show_search_modes = !useDiaryStore().show_search_modes"
                class="flex">
                <i v-if="useDiaryStore().show_search_modes"
                  class="fa-solid fa-chevron-up"></i>
                <i v-else
                  class="fa-solid fa-chevron-down"></i>
              </span>
              <div v-if="useDiaryStore().show_search_modes" 
                class="absolute left-0 border bg-white flex flex-col w-48 text-14px" 
                style="top: 40px; left: -8px; z-index:999;">
                  <div v-if="am_allowed('diary_roles.add_diary_product')" @click="useDiaryStore().search_item_mode = 1, useDiaryStore().show_search_modes = false"
                    :class="{'bg-blue-200' : useDiaryStore().search_item_mode == 1}"
                    class="border-b px-1 ">
                    Produkt i shpejte
                  </div>
                  <div v-if="am_allowed('diary_roles.add_diary_product')" @click="useDiaryStore().search_item_mode = 4, useDiaryStore().show_search_modes = false"
                    :class="{'bg-blue-200' : useDiaryStore().search_item_mode == 4}"
                    class="border-b px-1 ">
                    Produkt nga magazina
                  </div>
                  <div v-if="am_allowed('diary_roles.add_diary_service')" @click="useDiaryStore().search_item_mode = 2, useDiaryStore().show_search_modes = false"
                    :class="{'bg-blue-200' : useDiaryStore().search_item_mode == 2}"
                    class="border-b px-1 ">
                    Sherbime
                  </div>
                  <div v-if="am_allowed('diary_roles.can_add_free_line_product')"
                     @click="useDiaryStore().search_item_mode = 3, useDiaryStore().show_search_modes = false"
                    :class="{'bg-blue-200' : useDiaryStore().search_item_mode == 3}"
                    class="border-b px-1 ">
                    Kerkese per produkt
                  </div>
              </div>
            </div>
          </div>
          <div v-if="useDiaryStore().search_item_mode == 1" class="w-full flex flex-col">
            <!-- Add product from stock -->
            <div class="flex w-full justify-between">
              <span class="text-10px"> Produkte </span>
              <span class="text-10px"> Pos: {{ useDiaryStore().diary.pos_name }} </span>
            </div>
            <input type="text" placeholder="Kerko produktin..." class="border border-gray-300 p-1  text-xs"
              @keydown.enter="useSearchProcess().search_product_diary(useDiaryStore().process)" 
              v-model="useSearchProcess().search_val">
            <span class="absolute bottom-3 right-4  flex items-center">
              <i class="fa fa-search fa-xs"></i>
            </span>
          </div>
          <div v-else-if="useDiaryStore().search_item_mode == 2" class="w-full flex flex-col">
            <!-- Add service -->
            <span class="text-10px"> Sherbime </span>
            <input type="text" placeholder="Kerko sherbim..." class="border border-gray-300 p-1  text-xs"
              @keydown="useDiaryStore().search_service_diary()" 
              v-model="useDiaryStore().service_search_val">
            <span class="absolute bottom-3 right-4  flex items-center">
              <i class="fa fa-search fa-xs"></i>
            </span>
          </div>
          <div v-else-if="useDiaryStore().search_item_mode == 3" 
            class="flex flex-col" style="flex-grow:1;">
            <!-- Add free Line -->
            <span class="text-10px"> Kerkese per produkt </span>
            <div class="relative flex " style="flex-grow:1;">
              <input type="text" placeholder="Pershkrimi i produktit..." class="border border-gray-300 p-1  text-xs"
                v-model="useDiaryStore().new_free_line_description">
              <a @click.prevent="start_create_free_line"
                href="" class="border rounded flex items-center justify-center px-1 ml-2">
                <i class="fa-solid fa-plus"></i>
              </a>
            </div>
          </div>
          <div v-else-if="useDiaryStore().search_item_mode == 4" class="w-full flex justify-between">
            <!-- Add product from stock -->
            <span class="text-10px"> Produkte nga magazina </span>
            <span class="text-10px"> Pos: {{ useDiaryStore().diary.pos_name }} </span>
          </div>
        </div>
        <!-- Product Search results -->
        <div v-if="useDiaryStore().search_item_mode == 1" class="bg-white"
          style="max-height:55vh; overflow:auto;">
          <div class="flex w-full">
            <span v-if="useSearchProcess().search_results.length > 0" class="ml-auto text-xs mb-1">Stock</span>
          </div>
          <div v-for="result in useSearchProcess().search_results" :key="result.guid" 
            :class="[result?.expanded ? 'border-4 border-green-5003' : '']">
            <div class="product border p-1 hover:bg-sky-200 flex items-center justify-between bg-gray-100"
               :class="[result.expanded ? 'bg-green-50 border-b-2 border-t-2 border-green-600' : '']">
              <span @click="expandProduct(result)" 
                class="flex-grow">
                <i v-if="!result.same_location"
                   class="fa-solid fa-triangle-exclamation mx-1"
                  title="Ky produkt vjen nga nje magazine qe nuk ndodhet ne te njejten vendndodhje te pikes se shitjes."></i>
                {{ result.name }}
              </span>
              <span>
                <!-- <span>{{ numeral(result.price).format() }}</span> -->
                <span>{{ useDiaryStore().price_filter(result.price) }}</span>
                <span @click="useDiaryStore().toggle_result_active(result, null)" class="ml-5" style="text-transform: lowercase;">
                  <i v-if="result.is_active" class="fa-regular fa-square-check"></i>
                  <i v-else class="fa-regular fa-square"></i>
                </span>
              </span>
            </div>
            <div class="product-expanded bg-white" v-if="result.expanded">
              <transition name="fade">
                <div class="text-xs uppercase border-l border-r p-1 bg-green-50 font-thin"
                  v-if="result.accesories?.length > 0">
                  Aksesorët
                </div>
                <div v-else class="text-xs uppercase border-l border-r p-1 bg-gray-50 text-gray-400">
                  Nuk ka aksesorë
                </div>
              </transition>
              <div v-for="accessory in result.accesories" :key="accessory.guid"
                  :class="[
                    accessory.included ? 'bg-green-50' : '',
                    accessory.amount == 0 ? 'opacity-60' : '']"
                  class="accessory border p-1 py-2 hover:bg-sky-200 flex items-center justify-between bg-white text-xs">
                <span @click="includeAccessory(accessory, result)"
                  class="gorw">
                  <span class="cursor-pointer">
                    <i class="fa-regular fa-square-check" v-if="accessory.included"></i>
                    <i class="fa-regular fa-square" v-else></i>
                  </span>
                  <span class="flex-1 ml-1">
                    {{ accessory.name }}
                  </span>
                </span>
                <span>
                  <!-- <span>{{ numeral(accessory.price).format() }}</span> -->
                  <span>{{ useDiaryStore().price_filter(accessory.price) }}</span>
                  <span v-if="accessory.amount > 0" @click="useDiaryStore().toggle_result_active(result, accessory)"
                    class="pl-5">
                    <i v-if="accessory.is_active" class="fa-regular fa-square-check"></i>
                    <i v-else class="fa-regular fa-square"></i>
                  </span>
                  <span v-else class="pl-5">
                    <i class="fa-regular fa-square"></i>
                  </span>
                </span>
              </div>
              <div class="flex justify-between items-center border-t border-gray-300 p-1 bg-green-50">
                <span class="font-bold text-xs uppercase">Totali</span>
                <!-- <span class="flex-1 ml-2">{{ numeral(result.price + (result?.accessories_total ? result.accessories_total : 0)).format() }}</span> -->
                <span class="flex-1 ml-2">{{ useDiaryStore().price_filter(result.price + (result?.accessories_total ? result.accessories_total : 0)) }}</span>
                
                <span class="font-thin text-xs uppercase mr-2" v-if="result?.accessories_included > 0">+ {{ numeral(result?.accessories_total).format()}}</span>
                <button @click.prevent="add_diary_product_start(result, useDiaryStore().diary.guid) "
                  class="btn btn-sm text-gray-600 hover:border-black border p-1 bg-orange-300 shadow-md hover:bg-green-300 text-xs">
                  <i class="fa fa-plus mr-1"></i> Shto {{ result?.accessories_included > 0 ? (result?.accessories_included + 1) + ' Produkte' : 'Produkt' }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Service search results -->
        <div v-if="useDiaryStore().search_item_mode == 2 && useDiaryStore().service_search_results != null"
          class="mt-2"
          style="max-height:80vh; overflow-y:suto;">
          <div v-for="(key, idx) in Object.keys(useDiaryStore().service_search_results)" :key="idx"
            class="flex flex-col text-14px">
            <div class="bg-amber-100 text-10px px-1">{{key}}</div>
            <div v-for="(service, index) in useDiaryStore().service_search_results[key]" :key="index"
              :class="{'bg-slate-100': service.expanded}"
              class="flex flex-col px-2 py-1 border-b border-slate-200">
              <div class="flex">
                <div class="flex flex-col">
                  <span>{{service.name}}</span>
                  <span class="text-10px">{{service.description}}</span>
                </div>
                <div class="ml-auto flex flex-col">
                  <span class="text-right">
                    <span>
                      {{useDiaryStore().price_value_filter(service.price)}} 
                      <span class="text-slate-400 text-10px">Leke</span>
                    </span>
                  </span>
                  <span @click="add_diary_service_start(service)"
                    class="w-full bg-green-400 border rounded text-white px-2 text-14px
                      text-center border-green-600 hover:bg-green-500 cursor-pointer ml-auto"
                    style="width:fit-content;">
                    <i class="fa-solid fa-plus"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="useDiaryStore().search_item_mode == 4" 
          class="mt-1">
          <!-- Main search component -->
          <main-search
            :diary="useDiaryStore().diary"
            @close_prod_modal="useDiaryStore().toggleAddProduct = false"
          ></main-search>
        </div>
      </div>
    </div>

    <!-- BLOCK: Diary Buttons -->
    <div class="buttons-row flex items-center justify-start px-2 mt-3">
      <div v-if="!useDiaryStore().diary?.customer">
        Zgjidh klient
      </div>
      <div v-else class="w-full">
        <div v-if="useDiaryStore().diary?.status_id == 7">
          <div class="border rounded flex">
            <div class="border-r pl-2  flex flex-col" style="flex-grow:1;">
              <span class="border-b font-semibold">
                Ditar i mbyllur
              </span>
              <span v-if="useDiaryStore().diary.reject_reason_text" class="border-b">
                <i class="fa-solid fa-circle-info mr-1"></i> {{useDiaryStore().diary.reject_reason_text}}
              </span>
              <span v-if="useDiaryStore().diary.reject_text">
                <i class="fa-regular fa-comment ml-1"></i> {{useDiaryStore().diary.reject_text}}
              </span>
            </div>
            <div class="flex flex-col text-14px">
              <div class="px-3 flex border-b">
                <span class="py-1">
                  <i class="fa-solid fa-user"></i> {{useDiaryStore().diary.status_created_by}}
                </span>
              </div>
              <div class="px-3 flex ">
                <span class="py-1">
                  <i class="fa-solid fa-calendar-days"></i> {{ useDiaryStore().formatDate(useDiaryStore().diary.status_created_at)}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="useDiaryStore().diary?.status_id == 5">
          <div class="border rounded flex">
            <div class="border-r pl-2 flex flex-col text-left" style="flex-grow:1;">
              <span class="border-b font-semibold">
                Ditar i refuzuar nga klienti
              </span>
              <span v-if="useDiaryStore().diary.reject_reason_text" class="border-b">
                <i class="fa-solid fa-circle-info mr-1"></i> {{useDiaryStore().diary.reject_reason_text}}
              </span>
              <span v-if="useDiaryStore().diary.reject_text">
                <i class="fa-regular fa-comment ml-1"></i> {{useDiaryStore().diary.reject_text}}
              </span>
            </div>
            <div class="flex flex-col text-14px">
              <div class="px-3 flex border-b">
                <span class="py-1">
                  <i class="fa-solid fa-user"></i> {{useDiaryStore().diary.status_created_by}}
                </span>
              </div>
              <div class="px-3 flex ">
                <span class="py-1">
                  <i class="fa-solid fa-calendar-days"></i> {{ useDiaryStore().formatDate(useDiaryStore().diary.status_created_at)}}
                </span>
              </div>
            </div>
          </div>
        </div>


        <div v-else-if="useDiaryStore().diary?.status_id == 2">
          <div class="border rounded flex">
            <div class="border-r pl-2 items-center flex" style="flex-grow:1;">
              Ditar i perfunduar
            </div>
            <div class="flex flex-col text-14px">
              <div class="px-3 flex border-b">
                <span class="py-1">
                  <i class="fa-solid fa-user"></i> {{useDiaryStore().diary.status_created_by}}
                </span>
              </div>
              <div class="px-3 flex ">
                <span class="py-1">
                  <i class="fa-solid fa-calendar-days"></i> {{ useDiaryStore().formatDate(useDiaryStore().diary.status_created_at)}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="flex w-full justify-between text-14px">
          <!-- Close diary btn and statuses -->
          <div>
            <div class="relative">
                <!-- @click.prevent="useDiaryStore().show_close_statuses = !useDiaryStore().show_close_statuses" -->
              <button v-if="am_allowed('diary_roles.can_close_diary_manualy') && (useDiaryStore().diary.status_id != 7 && useDiaryStore().diary.status_id != 5)"
                @click.prevent="useDiaryStore().toggle_close_modal()"
                class="btn btn-sm btn-primary text-white hover:border-black border p-2 bg-red-800 shadow-md ml-auto hover:bg-red-900">
                <i class="fa-solid fa-xmark mr-1"></i> Mbyll ditarin
              </button>
            </div>
          </div>
          <div class="flex">
            <!-- Ruaj dhe mbyll, Dergo Per Konfirmim Zbritje, Processo ne Desk, Kalo ne Prijte -->
            <button v-if="![2, 7].includes(useDiaryStore().diary?.status_id) && am_allowed('diary_roles.can_see_request_discount')" @click.prevent="openProductList('discount')"
              class="btn btn-sm btn-primary text-white hover:border-black border p-2 bg-stone-600 shadow-md hover:bg-green-900">
              <i class="fa fa-percent mr-1"></i> Dergo Per Konfirmim Zbritje
            </button>
            <button v-if="useDiaryStore().diary?.status_id < 6 && am_allowed('diary_roles.can_process_diary') && !useDiaryStore().is_expired(useDiaryStore().diary?.valid_until_date)" @click.prevent="openProductList('process')"
              class="btn btn-sm btn-primary text-white hover:border-black border p-2 bg-sky-800 shadow-md hover:bg-green-900">
              <i class="fa fa-computer mr-1"></i> Proceso
            </button>
            <!-- <button @click.prevent="openProductList('postpone')"
              class="btn btn-sm btn-primary text-white hover:border-black border p-2 bg-sky-800 shadow-md hover:bg-green-900">
              <i class="fa fa-clock mr-1"></i> Shto kohe pritje
            </button> -->
            <button v-if="useDiaryStore().diary?.status_id < 7" @click.prevent="useDiaryStore().toggle_open_wait_modal()"
              class="btn btn-sm btn-primary text-white hover:border-black border p-2 bg-sky-800 shadow-md hover:bg-green-900">
              <i class="fa-regular fa-calendar-days mr-1"></i> Menaxho kohen e pritjes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="h-20">&nbsp;</div>

  <!-- loading overlay -->
  <div class="fixed top-0 left-0 h-full w-full flex items-center justify-center flex-col gap-1 bg-white bg-opacity-80"
    v-if="useDiaryStore().loading">
    <i class="fa fa-spinner fa-spin fa-2x"></i>
    <span class="font-thin">
      Po ngarkohet...
    </span>
  </div>

  <!-- BLOCK: Diary Products Modal -->
  <div v-if="toggleProductList" @click.self="toggleProductList = false"
    class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-start justify-center flex items-center justify-center" >
    <div class="bg-white flex-col w-5/6 mx-3 rounded overflow-y"
      style="max-height:calc(100vh - 75px); overflow-y:scroll;">
    <!-- Modal header -->
      <div class="p-2 border-b text-center flex justify-between items-center">
        <div class="w-1/3"></div>
        <div class="w-1/3 text-center font-semibold"> Produktet e ditarit </div>
        <div class="w-1/3 text-right">
          <div v-if="productModalMode == 'process'">
            <div v-if="useDiaryStore().desk_processes_loading">
              <i class="fa-solid fa-spinner fa-spin"></i>
            </div>
            <div v-else class="flex flex-col">
              <span class="text-slate-400" style="font-size:10px;">Procesi</span>
              <div v-if="useDiaryStore().desk_process_edit || !useDiaryStore().desk_process.guid" class="select is-small">
                <select v-model="useDiaryStore().selected_desk_process" @change="start_select_desk_process()">
                  <option v-for="(process, index) in useDiaryStore().desk_processes" :key="index" 
                    :value="process" >
                    {{ process.name }}</option>
                </select>
              </div>
              <div v-if="useDiaryStore().desk_process.guid && !useDiaryStore().desk_process_edit " 
                class="text-sm">
                <span >{{useDiaryStore().desk_process.name}}</span>
                <span @click="useDiaryStore().desk_process_edit = true"
                  class="pl-2 text-sky-400 text-xs">
                  <i class="fa-solid fa-pencil"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal body -->
      <div class="px-2 py-1">
        <div class="flex flex-col w-full" 
          style="overflow-y:scroll; max-height:500px;">
          <!-- Product list -->
          <div class="flex items center text-slate-400 items-top" style="font-size:13px;">
            <span class="pl-1" style="width:30px;">
              <span v-if="productModalMode == 'discount' && 
                useDiaryStore().diary?.status_id != 6 && 
                useDiaryStore().diary?.discount_request &&
                useDiaryStore().diary?.discount_request.length < 5 && 
                useDiaryStore().employee_discount_mode != 0">
                <span @click="useDiaryStore().select_all_products_discount()">
                  <i v-if="useDiaryStore().selected_for_discount.length == useDiaryStore().products.filter(a => a.is_active == 1 && a.related_entity == 1).length" class="fa-regular fa-square-check"></i>
                  <i v-else class="fa-regular fa-square"></i>
                </span>
              </span>
              <span v-else>#</span>
            </span>
            <span class="w-1/2">Emri i Produktit</span>
            <small v-if="useDiaryStore().employee_discount_mode == 2"
              class="ml-auto mr-2 text-slate-400 text-right" style="width:120px;">Zbritje</small>
            <!-- <small class="ml-auto mr-2 text-slate-400 text-right" style="width:50px;">Sasia</small> -->
            <div class="ml-auto" style="width:180px;">
              <div class="flex flex-col">
                <small class="  text-center border-b">Cmimi</small>
                <div class="flex">
                  <small class=" text-center w-1/2 border-r">Produkt</small>
                  <small class=" text-center w-1/2">Aktual</small>
                </div>
              </div>
            </div>
          </div>

          <div v-if="productModalMode != 'process'">
            <div v-for="(product, index) in useDiaryStore().products.filter(a => a.is_active == 1 && a.related_entity == 1 )" :key="index"
              class="flex  cursor-pointer items-top border-b border-t border-slate-100 ">
              <span class="px-2" style="width:30px;">
                <span v-if="productModalMode == 'discount' && 
                  useDiaryStore().diary?.status_id != 6 && 
                  useDiaryStore().diary?.discount_request &&
                  useDiaryStore().diary?.discount_request.length < 5 && 
                  useDiaryStore().employee_discount_mode != 0">
                  <span @click="useDiaryStore().toggle_discount_product(product)">
                    <i v-if="useDiaryStore().selected_for_discount.includes(product)" class="fa-regular fa-square-check"></i>
                    <i v-else class="fa-regular fa-square"></i>
                  </span>
                </span>

                <span v-if="productModalMode == 'process'">
                  <span @click="useDiaryStore().toggle_process_product(product)">
                    <i v-if="useDiaryStore().selected_for_process.includes(product)" class="fa-regular fa-square-check"></i>
                    <i v-else class="fa-regular fa-square"></i>
                  </span>
                </span>

              </span>
              <span class="w-1/2 flex flex-col">
                {{product.product_name}}
                <small class="text-slate-400 text-10px ml-1">{{product.product_code}}</small>

              </span>
              <div v-if="useDiaryStore().employee_discount_mode == 2"
                class="ml-auto mr-2  text-right" style="width:120px;">
                <div v-if="useDiaryStore().selected_for_discount.includes(product)">
                  <div class="flex flex-col">
                    <div class="flex">
                        <!-- :class="{'border-red-500': product.employee_discount_value > useDiaryStore().diary?.max_employee_discount}" -->
                      <input v-model="product.employee_discount_value" @keyup="useDiaryStore().calc_prod_discount_value(product)" type="number" 
                        style="-moz-appearance: none; -webkit-appearance: none; margin: 0; "
                        class="border-t border-b border-l rounded-l text-right px-1 text-black"> 
                      <a @click.prevent="useDiaryStore().toggle_employee_discount_type(product)" 
                        class="border border-r rounded-r px-2 text-14px flex items-center">
                        <span v-if="product.employee_discount_type == 1"> % </span>
                        <span v-if="product.employee_discount_type == 0"> Leke </span>
                      </a>
                    </div>
                    <div class="flex text-slate-400"
                      :class="{'text-red-500!important': product.employee_discount_value > useDiaryStore().diary?.max_employee_discount}">
                      <small class="text-8px ">Skonto max {{useDiaryStore().format_percentage(useDiaryStore().diary?.max_employee_discount)}}.</small>
                    </div>
                    <!-- <div v-if="product.discount_warning" 
                      class="text-red-400 text-10px" style="white-space: nowrap;">
                      <i class="fa-solid fa-triangle-exclamation"></i>
                      <span v-if="product.actual_discount > 0">
                        Skonto e dhene per kete produkt eshte {{useDiaryStore().format_percentage(product.actual_discount)}} 
                      </span>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="flex ml-auto" style="width:180px;">
                <span class="w-1/2 text-right border-r pr-2 " >
                  <span>
                    {{useDiaryStore().price_value_filter(product.price)}} 
                    <span class="text-slate-400 text-10px">Leke</span>
                  </span>
                </span>
                <span class="flex flex-col w-1/2 text-right text-green-700 font-semibold" >
                  <span >
                    <span :class="{'line-through opacity-50': product.temp_discounted_price}">
                      {{product.discounted_price > 0 ? useDiaryStore().price_value_filter(product.discounted_price) : 
                        useDiaryStore().price_value_filter(product.price)}} 
                    </span>
                    <span class="text-slate-400 text-10px">Leke</span>
                  </span>
                  <span v-if="product.discounted_price > 0" class="text-8px text-slate-400 flex flex-col">
                    <span> 
                      Zbritje {{useDiaryStore().format_percentage(useDiaryStore().product_discount_value(product))}}
                      ({{useDiaryStore().price_value_filter(parseFloat(product.price) - parseFloat(product.discounted_price))}}Leke)
                    </span>
                  </span>
                  <div v-if="product.temp_discounted_price"
                      class="text-green-700"
                      style="white-space: nowrap;">
                      {{ useDiaryStore().price_value_filter(product.temp_discounted_price)}} 
                      <span class="text-slate-400 text-10px">Leke</span>
                    </div>
                </span>
              </div>
            </div>
            <div class="flex pl-2">
              <div class="w-1/2 text-slate-400">Totali i produkteve</div>
              <div style="width:30px;"></div>
              <div class="flex ml-auto " style="width:180px;"> 
                <span class="w-1/2 py-2 text-right border-r pr-2">
                  <span v-if="useDiaryStore().have_discount">
                    {{ useDiaryStore().price_value_filter(useDiaryStore().active_total_no_discount) }}
                    <span class="text-slate-400 text-10px">Leke</span>
                  </span> 
                </span>
                <div class="w-1/2 py-2 flex flex-col  ">
                  <span class="text-right text-green-700 font-semibold">
                    {{useDiaryStore().price_value_filter(useDiaryStore().active_total)}}
                    <span class="text-slate-400 text-10px">Leke</span>
                  </span>
                  <span v-if="useDiaryStore().have_discount" 
                    class="flex flex-col text-10px text-right">
                    <span>
                      <!-- Zbritje {{useDiaryStore().average_discount.percentage.toFixed(2)}}% -->
                      Zbritje {{useDiaryStore().format_percentage(useDiaryStore().price_value_filter(useDiaryStore().average_discount.percentage))}}
                    </span>
                    <span>
                      ({{useDiaryStore().price_value_filter(useDiaryStore().average_discount.value)}}
                      <span class=" text-10px">Leke</span>)
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div v-for="(product, index) in 
              useDiaryStore().products.filter(a => {
                if(a.is_active == 1 && a.related_entity == 1) return true
                else if(a.parent_entity == null && a.related_entity == 2) return true
              })" :key="index" class=" ">

              <div class="flex cursor-pointer items-top border-b border-t border-slate-100">
                <span class="px-2" style="width:30px;">
                  <span v-if="productModalMode == 'process'">
                    <span @click="useDiaryStore().toggle_process_product(product)">
                      <i v-if="useDiaryStore().selected_for_process.includes(product)" class="fa-regular fa-square-check"></i>
                      <i v-else class="fa-regular fa-square"></i>
                    </span>
                  </span>
                </span>
                <span class="w-1/2 flex flex-col">
                  {{product.related_entity == 1 ? product.product_name : 
                    product.related_entity == 2 ? product.service_name : null}}
                  <small class="text-slate-400 text-10px ml-1">
                    {{product.related_entity == 1 ? product.product_code : 
                    product.related_entity == 2 ? product.service_code : null}}
                  </small>
                </span>
                <div class="flex ml-auto" style="width:180px;">
                  <span class="w-1/2 text-right border-r pr-2 " >
                    <span>
                      {{
                        product.related_entity == 1 ? useDiaryStore().price_value_filter(product.price) :
                        product.related_entity == 2 ? useDiaryStore().price_value_filter(product.service_price) : null
                      }} 
                      <span class="text-slate-400 text-10px">Leke</span>
                    </span>
                  </span>
                  <span class="flex flex-col w-1/2 text-right text-green-700 font-semibold" >
                    <span>
                      <span v-if="product.related_entity == 1">
                        {{product.discounted_price > 0 ? useDiaryStore().price_value_filter(product.discounted_price) : 
                          useDiaryStore().price_value_filter(product.price)}} 
                      </span>
                      <span v-else-if="product.related_entity == 2">
                        {{useDiaryStore().price_value_filter(product.service_price)}}
                      </span>
                      <span class="text-slate-400 text-10px">Leke</span>
                    </span>
                    <span v-if="product.discounted_price > 0" class="text-8px text-slate-400">
                      Zbritje {{useDiaryStore().price_value_filter(useDiaryStore().product_discount_value(product))}}
                    </span>
                    <!-- <div v-if="product.temp_discounted_price"
                      class="text-green-700"
                      style="white-space: nowrap;">
                      {{ useDiaryStore().price_value_filter(product.temp_discounted_price)}} 
                      <span class="text-slate-400 text-10px">Leke</span>
                    </div> -->
                  </span>
                </div>
              </div>

              <div v-if="product.transport_service?.guid" 
                class="flex cursor-pointer items-top border-b border-t border-slate-100 ml-3">
                <span class="px-2" style="width:30px;">
                  <span v-if="productModalMode == 'process'">
                    <span @click="useDiaryStore().toggle_process_product(product)">
                      <i v-if="useDiaryStore().selected_for_process.includes(product)" class="fa-regular fa-square-check"></i>
                      <i v-else class="fa-regular fa-square"></i>
                    </span>
                  </span>
                </span>
                <span class="w-1/2 flex flex-col">
                  {{product.transport_service.name}}
                  <small class="text-slate-400 text-10px ml-1">
                    {{product.transport_service.code}}
                  </small>
                </span>
                <div class="flex ml-auto" style="width:180px;">
                  <span class="w-1/2 text-right border-r pr-2 " >
                    <span>
                      {{ useDiaryStore().price_value_filter(product.transport_service.price) }}
                      <span class="text-slate-400 text-10px">Leke</span>
                    </span>
                  </span>
                  <span class="flex flex-col w-1/2 text-right text-green-700 font-semibold" >
                    <span>
                      <span >
                        {{useDiaryStore().price_value_filter(product.transport_service.price)}}
                      </span>
                      <span class="text-slate-400 text-10px">Leke</span>
                    </span>
                  </span>
                </div>
              </div>

              <!-- Pickup service -->
              <div v-if="product.pickup_service?.pickup_guid" 
                class="flex cursor-pointer items-top border-b border-t border-slate-100 ml-3">
                <span class="px-2" style="width:30px;">
                  <span v-if="productModalMode == 'process'">
                    <span @click="useDiaryStore().toggle_process_product(product)">
                      <i v-if="useDiaryStore().selected_for_process.includes(product)" class="fa-regular fa-square-check"></i>
                      <i v-else class="fa-regular fa-square"></i>
                    </span>
                  </span>
                </span>

                <span class="w-1/2 flex flex-col justify-center">
                  
                  <small class="text-slate-400 text-10px ml-1" v-if="product.pickup_service.customer_request == 0">
                    <span v-if="product.pickup_service.same_location == 0">
                      Do te transportohet nga 
                      <b>
                        <i class="fa fa-warehouse"></i> 
                        {{product.pickup_service.from_warehouse_name}}
                      </b> per dorezim klientit tek 
                      <b v-if="product.pickup_service.pickup_warehouse_original">
                        <i class="fa fa-warehouse"></i> 
                        {{product.pickup_service.pickup_warehouse_name}}
                      </b>
                      <b v-else-if="product.pickup_service.pickup_pos_original">
                        <i class="fa fa-store"></i> 
                        {{product.pickup_service.pickup_pos_name}}
                      </b>
                    </span>
                    
                    <span v-else-if="product.pickup_service.same_location == 1">
                      Do te terhiqet nga klientit tek 
                      <b v-if="product.pickup_service.pickup_pos_original">
                        <i class="fa fa-store"></i> 
                        {{product.pickup_service.pickup_pos_name}}
                      </b>

                      <b v-if="product.pickup_service.pickup_warehouse_original">
                        <i class="fa fa-store"></i> 
                        {{product.pickup_service.pickup_warehouse_name}}
                      </b>

                    </span>

                  </small>
                </span>
                <div class="flex ml-auto" style="width:180px;">
                  <span class="w-1/2 text-right border-r pr-2 " >
                    <span>
                      {{ useDiaryStore().price_value_filter(product.pickup_service.price) }}
                      <span class="text-slate-400 text-10px">Leke</span>
                    </span>
                  </span>

                  <span class="flex flex-col w-1/2 text-right text-green-700 font-semibold" >
                    <span>
                      <span >
                        {{useDiaryStore().price_value_filter(product.pickup_service.price)}}
                      </span>
                      <span class="text-slate-400 text-10px">Leke</span>
                    </span>
                  </span>
                </div>
              </div>
              <div v-if="product.mountable_service?.guid" 
                class="flex cursor-pointer items-top border-b border-t border-slate-100 ml-3">
                <span class="px-2" style="width:30px;">
                  <span v-if="productModalMode == 'process'">
                    <span @click="useDiaryStore().toggle_process_product(product)">
                      <i v-if="useDiaryStore().selected_for_process.includes(product)" class="fa-regular fa-square-check"></i>
                      <i v-else class="fa-regular fa-square"></i>
                    </span>
                  </span>
                </span>
                <span class="w-1/2 flex flex-col">
                  {{product.mountable_service.name}}
                  <small class="text-slate-400 text-10px ml-1">
                    {{product.mountable_service.code}}
                  </small>
                </span>
                <div class="flex ml-auto" style="width:180px;">
                  <span class="w-1/2 text-right border-r pr-2 " >
                    <span>
                      {{ useDiaryStore().price_value_filter(product.mountable_service.price) }}
                      <span class="text-slate-400 text-10px">Leke</span>
                    </span>
                  </span>
                  <span class="flex flex-col w-1/2 text-right text-green-700 font-semibold" >
                    <span>
                      <span >
                        {{useDiaryStore().price_value_filter(product.mountable_service.price)}}
                      </span>
                      <span class="text-slate-400 text-10px">Leke</span>
                    </span>
                  </span>
                </div>
              </div>
              <div v-if="product.reservation_service?.guid" 
                class="flex cursor-pointer items-top border-b border-t border-slate-100 ml-3">
                <span class="px-2" style="width:30px;">
                  <span v-if="productModalMode == 'process'">
                    <span @click="useDiaryStore().toggle_process_product(product)">
                      <i v-if="useDiaryStore().selected_for_process.includes(product)" class="fa-regular fa-square-check"></i>
                      <i v-else class="fa-regular fa-square"></i>
                    </span>
                  </span>
                </span>
                <span class="w-1/2 flex flex-col">
                  {{product.reservation_service.name}}
                  <small class="text-slate-400 text-10px ml-1">
                    {{product.reservation_service.code}}
                  </small>
                </span>
                <div class="flex ml-auto" style="width:180px;">
                  <span class="w-1/2 text-right border-r pr-2 " >
                    <span>
                      {{ useDiaryStore().price_value_filter(product.reservation_service.price) }}
                      <span class="text-slate-400 text-10px">Leke</span>
                    </span>
                  </span>
                  <span class="flex flex-col w-1/2 text-right text-green-700 font-semibold" >
                    <span>
                      <span >
                        {{useDiaryStore().price_value_filter(product.reservation_service.price)}}
                      </span>
                      <span class="text-slate-400 text-10px">Leke</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="flex pl-2">
              <div class="w-1/2 text-slate-400">Totali i produkteve</div>
              <div style="width:30px;"></div>
              <div class="ml-auto" style=""> </div>
              <div class="flex ml-auto " style="width:180px;"> 
                <span class="w-1/2 py-2 text-right border-r pr-2">
                  <span v-if="useDiaryStore().have_discount">
                    {{ useDiaryStore().price_value_filter(useDiaryStore().process_modal_no_discount) }}
                    <span class="text-slate-400 text-10px">Leke</span>
                  </span> 
                </span>
                <div class="w-1/2 py-2 flex flex-col  ">
                  <span class="text-right text-green-700 font-semibold">
                    {{useDiaryStore().price_value_filter(useDiaryStore().process_modal_total)}}
                    <span class="text-slate-400 text-10px">Leke</span>
                  </span>
                  <span v-if="useDiaryStore().have_discount" 
                    class="flex flex-col text-10px text-right">
                    <span>
                      <!-- Zbritje {{useDiaryStore().average_discount.percentage.toFixed(2)}}% -->
                      Zbritje {{useDiaryStore().price_value_filter(useDiaryStore().average_discount.percentage)}}
                      
                    </span>
                    <span>
                      ({{useDiaryStore().price_value_filter(useDiaryStore().average_discount.value)}}
                      <span class=" text-10px">Leke</span>)
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- Modal footer -->
      <div class="p-2 border-t bg-slate-50 rounded-b flex flex-col">
        <div class=" flex items-center ">
          <!-- Backup - sistemo ne tablet -->
          <!-- <div v-if="productModalMode == 'discount' &&
             useDiaryStore().diary?.status_id != 6 &&
             ( useDiaryStore().diary?.discount_request && useDiaryStore().diary?.discount_request.length < 5) && 
             useDiaryStore().employee_discount_mode != 0 && 
             useDiaryStore().selected_for_discount.length == 0" 
            class="border border-slate-300 rounded px-2 py-1 text-sm" 
            style="font-size:12px;">
            <div class="flex flex-col"> 
              <span v-if="useDiaryStore().selected_for_discount.length == 0">
                Zgjidh nje ose disa nga produktet me siper. 
              </span>
            </div>
          </div> -->
          <span v-if="productModalMode == 'discount' && useDiaryStore().diary?.status_id == 6"
            class="border border-slate-300 rounded px-2 py-1 text-sm">
            Ne pritje te kerkeses per cmim
          </span>
          <span v-if="productModalMode == 'postpone'" class="border border-slate-300 rounded px-2 py-1 text-sm " style="font-size:12px;">
            Postpone info text
          </span>
          <!-- <div class="ml-auto w-2/3 flex justify-end"> -->
          <div class="w-full md:ml-auto md:w-2/3 md:flex justify-end">
            <div v-if="productModalMode == 'discount' && useDiaryStore().diary?.status_id != 6 && (useDiaryStore().diary?.discount_request && useDiaryStore().diary?.discount_request.length < 5)" >
              <div v-if="useDiaryStore().employee_discount_mode == 0" class="flex w-full justify-end">
                <a v-if="useDiaryStore().can_give_discount && useDiaryStore().diary?.max_employee_discount > 0" 
                  @click.prevent="useDiaryStore().employee_discount_mode = 2"
                  class="px-2 py-1 bg-slate-600 border border-slate-900 text-white hover:text-white rounded text-sm mr-2"
                  href="">
                  Jep Zbritje</a>
                <a v-if="am_allowed('diary_roles.can_request_discount')"
                   @click.prevent="useDiaryStore().start_request_discount()" 
                  class="px-2 py-1 bg-slate-600 border border-slate-900 text-white hover:text-white rounded text-sm" href="">
                  Kerko Zbritje</a>
              </div>
              <div v-else-if="useDiaryStore().employee_discount_mode == 1">
                <span v-if="useDiaryStore().loading_request_discount"
                  class=" px-2 py-1 bg-slate-600 border border-slate-900 text-white hover:text-white rounded text-sm" >
                  <i class="fa fa-spinner fa-spin"></i>
                </span>
                <div v-else class="flex items-end ">
                  <div class="flex flex-col mr-2 ml-auto w-48">
                    <span class="text-slate-400 text-10px text-right">Kush do e aprovoje kerkesen </span>

                    <div class="relative flex justify-end">
                      <span class="text-slate-400 text-10px flex items-center mr-2">
                          {{useDiaryStore().selected_approver ? useDiaryStore().selected_approver.name : ''}}
                      </span>
                      <a @click.prevent="useDiaryStore().toggle_approvers_list()" href="" 
                        class="border rounded  pt-1 px-2 bg-white">
                        <i class="fa-solid fa-angle-down"></i>
                      </a>
                      <div v-if="useDiaryStore().show_approvers_list" 
                        class="border rounded bg-white absolute w-40 md:right-0" style="top: 30px;">
                        <div v-for="(approver, index) in useDiaryStore().discount_approvers" :key="index"
                          :class="{'bg-slate-100': useDiaryStore().diary.discount_assigned_to == approver.id}"
                          @click.prevent="useDiaryStore().set_diary_approver(approver)"
                          class="w-full text-14px px-1 flex border-b cursor-pointer hover:bg-slate-100">
                          <span> {{approver.name}} </span>
                          <span class="text-right ml-auto">
                            ({{approver.max_employee_discount}})
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col mr-2 w-48">
                    <span class="text-slate-400 text-10px text-right">Shto kohen e vleshmerise se ditarit</span>
                    <select v-model="useDiaryStore().postpone_value" name="" id="" 
                      class="border border-slate-300 rounded py-1 text-14px">
                      <option :value="0">Zgjidh sa ore</option>
                      <option :value="2">2 ore</option>
                      <option :value="3">3 ore</option>
                      <option :value="6">6 ore</option>
                      <option :value="12">12 ore</option>
                    </select>
                  </div>
                  <div class="flex flex-col">
                    <div class="flex justify-end text-12px text-slate-400 mr-2 mb-2">
                      <span @click="useDiaryStore().employee_discount_mode = 0" class="">
                        <i class="fa-solid fa-xmark"></i>
                      </span>
                    </div>
                    <span v-if="useDiaryStore().selected_for_discount.length == 0"
                      class="text-8px text-slate-400">
                      Zgjidh nje ose disa nga produktet me siper. 
                    </span>
                    <a 
                      @click.prevent="start_request_discount('discount')"
                      :class="{'opacity-50': useDiaryStore().selected_for_discount.length == 0}"
                      class=" px-2 py-1 bg-slate-600 border border-slate-900 text-white hover:text-white rounded text-sm" 
                      style="width: max-content;"
                      href="">Dergo Per Konfirmim Zbritje</a>
                  </div>
                </div>
              </div>
              <div v-if="useDiaryStore().employee_discount_mode == 2" key="" class="flex flex-col">
                <div class="flex justify-end text-12px text-slate-400 mr-2 mb-2">
                  <span @click="useDiaryStore().cancel_employee_discount()" class="">
                    <i class="fa-solid fa-xmark"></i>
                  </span>
                </div>
                <span v-if="useDiaryStore().loading_request_discount"
                  class=" px-2 py-1 bg-slate-600 border border-slate-900 text-white hover:text-white rounded text-sm" >
                  <i class="fa fa-spinner fa-spin"></i>
                </span>
                <a v-else
                  @click.prevent="start_employee_discount_request()"
                  :class="{'opacity-50': useDiaryStore().selected_for_discount.length == 0}"
                  class=" px-2 py-1 bg-slate-600 border border-slate-900 text-white hover:text-white rounded text-sm" 
                  href="">Konfirmo </a>
              </div>
            </div>
            <a v-if="productModalMode == 'process'" 
              @click.prevent="start_process_desk()"
              :class="{'opacity-60': useDiaryStore().selected_for_process.length == 0 || !useDiaryStore().selected_desk_process }"
              class=" px-2 py-1 bg-slate-600 border border-slate-900 text-white hover:text-white rounded text-sm" 
              href="">Proceso tek {{useDiaryStore().selected_desk_process?.name}} <i class="fa-solid fa-arrow-right"></i></a>
          </div>
        </div>
        <div v-if="productModalMode == 'discount' &&
           useDiaryStore().diary?.status_id != 6  &&
           (useDiaryStore().diary?.discount_request && useDiaryStore().diary?.discount_request.length < 5) &&
           useDiaryStore().employee_discount_mode != 0"
          class="flex flex-col text-14px">
          <label class="text-slate-400 ">Shenime per kerkesen</label>
          <textarea v-model="useDiaryStore().diary.discount_request_note"
            class="border rounded border-slate-500 p-1 bg-blue-50" rows="4" cols="50">
          </textarea>
        </div>
        <div v-if="productModalMode == 'discount' && (useDiaryStore().diary?.discount_request && useDiaryStore().diary?.discount_request.length > 0)" 
          class="border rounded p-1  border-blue-500 my-3">
          <div class="text-14px flex justify-between">
            <span>
              Kerkesat per cmim te ketij ditari
            </span>
            <span>
              {{useDiaryStore().diary?.discount_request ? useDiaryStore().diary?.discount_request.length : 0}} / 5 max
            </span>
          </div>
          <table class="table w-full table-products is-narrow ">
            <thead>
              <tr class="faded-header">
                <th class="text-10px text-slate-100">ID</th>
                <th class="text-10px text-slate-100">Kerkuar nga</th>
                <th class="text-10px text-slate-100">Kerkuar me</th>
                <th class="text-10px text-slate-100">Caktuar per</th>
                <th class="text-10px text-slate-100">Perditesuar nga</th>
                <th class="text-10px text-slate-100">Perditesuar me</th>
                <th class="text-10px text-slate-100">Statusi</th>
                <th class="text-right">#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="request in useDiaryStore().diary?.discount_request" :key="request.guid" 
                >
                <td>{{request.id}}</td>
                <td>{{request.requested_by_name}}</td>
                <td>{{useDiaryStore().formatDate(request.created_at)}}</td>
                <td>
                  {{request.assigned_to_name ? request.assigned_to_name : 'Pa percaktuar'}}
                </td>
                <td>
                  {{request.status_updated_by_name ? request.status_updated_by_name : ''}}
                  <span v-if="request.by_employee == 1 && request.request_status == 2">
                    <i class="fa-solid fa-user"></i>
                  </span>
                </td>
                <td>
                  <span v-if="request.by_employee == 1 && request.request_status == 2">
                    {{ useDiaryStore().formatDate(request.created_at) }}
                  </span>
                  <span v-else>
                    {{request.status_updated_at ? useDiaryStore().formatDate(request.status_updated_at) : ''}}
                  </span>
                </td>
                <td :class="{
                  'text-red-700': request.request_status == 3, 
                  'text-green-700': request.request_status == 2 || request.by_employee == 1,
                  'text-blue-500' : request.request_status == 1
                   }">
                    <span v-if="request.by_employee == 1 && request.request_status == 2">
                      <i class='fa-solid fa-check'></i>
                    </span>
                    <span v-else-if="request.request_status == 1">
                      <i class="fa-solid fa-hourglass-half"></i>
                    </span>
                    <span v-else-if="request.request_status == 2">
                      <i class='fa-solid fa-check'></i>
                    </span>
                    <span v-else-if="request.request_status == 3">
                      <i class="fa-solid fa-xmark"></i>
                    </span>
                    <span v-else-if="request.request_status == 4">
                      <i class="fa-solid fa-trash-can text-red-500"></i>
                    </span>

                  <!-- {{
                    request.by_employee == 1 && request.request_status == 2 ? 'Zbritje nga operatori' :
                    request.request_status == 1 ? 'Ne pritje te komfirmimit' :
                    request.request_status == 2 ? "<i class='fa-solid fa-check'></i>" : 
                    request.request_status == 3 ? 'Kerkese e refuzuar' : ''
                  }} -->
                </td>
                <td class="text-right flex justify-end">
                  <div @click="open_discount_template(request.guid)" 
                    class="cursor-pointer">
                    <i class="fa-solid fa-up-right-from-square"></i>
                  </div>
                  <div v-if="request.request_status == 1 && (
                    useDiaryStore().current_user.id == request.by_employee || 
                    am_allowed('diary_roles.can_cancel_discount')
                  )" 
                    @click="useDiaryStore().cancel_discount_request(request)"
                    class="cursor-pointer mx-2 text-red-500"
                    title="I anulluar">
                    <i class="fa-solid fa-trash-can"></i>
                  </div>
                  
                  <div v-if="useDiaryStore().can_edit_employee_discount"
                    @click.prevent="useDiaryStore().edit_employee_discount()"
                    class="ml-2 cursor-pointer">
                    <i class="fa-solid fa-pencil"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- Wait modal -->
  <div v-if="useDiaryStore().show_wait_modal" @click.self="useDiaryStore().toggle_open_wait_modal"
    class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-start justify-center flex items-center justify-center">
    <div class="bg-white flex-col w-5/6 mx-3 rounded overflow-y"
      style="max-height:calc(100vh - 75px); overflow-y:scroll;">
      <!-- Modal header -->
      <div class="p-2 border-b text-center flex justify-between items-center">
        <div>
          Menaxho kohen e pritjes se ditarit
        </div>
      </div>
      <!-- Modal body -->
      <div class="p-2 py-1">
        <div class="flex flex-col w-full text-12px" 
          style="overflow-y:scroll; max-height:500px;">
          <div class="flex justify-around mb-3">
            <div @click="useDiaryStore().toggle_time_mode(0)" 
              :class="{'border-blue-500 border-2': useDiaryStore().manage_time_mode == 0}"
              class="border rounded py-1 cursor-pointer w-32 text-center ">
              Nga shitesi
            </div>
            <div @click="useDiaryStore().toggle_time_mode(1)" 
              :class="{'border-blue-500 border-2': useDiaryStore().manage_time_mode == 1}"
              class="border rounded py-1 cursor-pointer w-32 text-center ">
              Nga klienti
            </div>
          </div>
          <!-- Kalo ne pritje nga klienti -->
          <div v-if="useDiaryStore().manage_time_mode == 1" class="flex flex-col"
            :class="{'mx-5 border rounded margin-auto px-1 mb-2' : useDiaryStore().diary.requested_by_customer && !useDiaryStore().customer_waiting_edit_mode}">
            <div class="flex flex-col">
              <span class="flex justify-between">
                Data e kerkuar nga klienti
                <span v-if="useDiaryStore().customer_waiting_edit_mode"
                  @click="useDiaryStore().customer_waiting_edit_mode = false">
                  <i class="fa-solid fa-xmark cursor-pointer"></i>
                </span>
              </span>
              <Datepicker
                v-if="!useDiaryStore().diary.requested_by_customer || useDiaryStore().customer_waiting_edit_mode"
                v-model="useDiaryStore().diary.requested_by_customer_new"
                :format="'dd/MM/yyyy HH:mm'"
                class="datepicker-wrap-pickup p-0"
                :class="{'border rounded border-red-500': !useDiaryStore().diary.requested_by_customer_new}"
              />
              <span v-else class="text-14px font-semibold">
                {{formatDate(useDiaryStore().diary.requested_by_customer)}}
              </span>
                <!-- :class="{'disabled': }" -->
            </div>
            <div>
              <span>Kontakt ose shenime rreth kerkeses</span>
              <textarea v-if="!useDiaryStore().diary.requested_by_customer || useDiaryStore().customer_waiting_edit_mode" v-model="useDiaryStore().diary.requested_text_new" 
                class="border rounded px-2 w-full" rows="4">
              </textarea>
              <p v-else class="text-14px font-semibold">
                {{useDiaryStore().diary.requested_text}}
              </p>
            </div>
            <div class="w-full flex text-14px">
              <div v-if="useDiaryStore().diary.requested_by_customer"
                class="flex gap-2 w-full ">
                <span v-if="!useDiaryStore().customer_waiting_edit_mode"
                  @click="start_edit_customer_waiting()" class="flex-grow text-center border rounded bg-blue-500 text-white cursor-pointer">Modifiko</span>
                <span v-else @click="useDiaryStore().save_postpone_request" 
                  class="flex-grow text-center border rounded bg-blue-500 text-white cursor-pointer">Ruaj modifikimin</span>
              </div>
              <span v-else-if="!useDiaryStore().loading_postpone" @click="start_postpone_request()" 
                class="w-full border rounded border-green-800 bg-green-500 text-center text-white cursor-pointer"
                :class="{'opacity-50': !useDiaryStore().diary.requested_by_customer_new || useDiaryStore().loading_postpone }">
                <span v-if="useDiaryStore().loading_postpone">
                  <i class="fa fa-spinner fa-spin"></i>
                </span>
                <span v-else> Ruaj </span>
              </span>
            </div>
          </div>
          <!-- Shto kohen e ditarit -->
          <div v-if="useDiaryStore().manage_time_mode == 0" class="flex flex-col mx-5">
            <div v-if="useDiaryStore().diary.employee_requested_text"
              class="flex flex-col border rounded mb-2 px-1">
              <span>
                <i class="fa-solid fa-circle-info mr-1 text-slate-400"></i> 
                Arsyet e shtimit te fundit te kohes: 
              </span>
              <span>
                {{useDiaryStore().diary.employee_requested_text}}
              </span>
            </div>
            <div class="text-12px">
              <i class="fa-solid fa-circle-info mr-1 text-slate-400"></i>
              Shto kohen e pritjes se ditarit
            </div>
            <div class="flex">
              <select v-model="useDiaryStore().postpone_value"
                :class="[ !useDiaryStore().postpone_value || useDiaryStore().postpone_value == 0 ? 'border-red-500' : 'border-slate-300']"
                name="" id="" class="border  rounded py-1 mr-2">
                <option :value="0">Zgjidh sa ore</option>
                <option :value="2">2 ore</option>
                <option :value="3">3 ore</option>
                <option :value="6">6 ore</option>
                <option :value="12">12 ore</option>
              </select>
              <span v-if="useDiaryStore().postpone_diary_loading"
                class="px-2 py-1 bg-slate-600 border border-slate-900 
                text-white hover:text-white rounded text-sm w-64 text-center mr-2">
                  <i class="fa fa-spinner fa-spin"></i>
              </span>
              <a v-else
                @click.prevent="start_postpone_diary()"
                class=" px-2 py-1 bg-slate-600 border border-slate-900 
                  text-white hover:text-white rounded text-sm w-64 text-center" 
                  :class="{'opacity-50': useDiaryStore().postpone_value == 0 || !useDiaryStore().diary.employee_requested_text_new}" 
                href=""> Shto kohe pritje </a>
            </div>
            <div class="flex flex-col">
              <span>Arsyet e shtimit te kohes</span>
              <textarea v-model="useDiaryStore().diary.employee_requested_text_new" 
                :class="{'border-red-500' : !useDiaryStore().diary.employee_requested_text_new}"
                class="border rounded px-2 w-full" rows="4">
              </textarea>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

   <!-- Close diary modal -->
  <div v-if="useDiaryStore().show_close_modal" @click.self="useDiaryStore().toggle_close_modal()"
    class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-start justify-center flex items-center justify-center">
      <div class="bg-white flex-col w-5/6 mx-3 rounded overflow-y" 
        style="max-height:calc(100vh - 75px); overflow-y:scroll;">
        <!-- Modal header -->
        <div class="p-2 border-b text-center flex justify-between items-center">
          <div></div>
          <div>
            Mbyll ditarin
          </div>
              <span @click="useDiaryStore().diary.show_close_modal = false">
                <i class="fa-solid fa-xmark cursor-pointer"></i>
              </span>
        </div>
        <!-- Modal body -->
        <div class="p-2 border-t w- rounded-b flex">
          <div class="flex flex-col text-14px w-full">
            <div class="flex justify-between mx-5 px-2">
              <span></span>
            </div>
            <div class="p-2 flex flex-col text-14px">
              <div class="flex flex-col mx-5 mb-2">
                <span> Statusi </span>
                <select v-model="useDiaryStore().diary.cancel_status" class="border rounded">
                  <option :value="null">Zgjidh status</option>
                  <option :value="5">I Refuzuar nga klienti</option>
                  <option :value="7">I Mbyllur</option>
                </select>
              </div>
              <div class="flex flex-col mx-5 mb-2">
                <span> Arsyet </span>
                <select v-model="useDiaryStore().diary.reject_reason" class="border rounded">
                  <option :value="null">Zgjidh arsye</option>
                  <option v-for="(reason, index) in useDiaryStore().reject_reasons" :key="index"
                    :value="reason.guid">{{reason.text}}</option>
                </select>
              </div>
              <div class="flex flex-col mx-5">
                <span>Shenime</span>
                <textarea v-model="useDiaryStore().diary.reject_text"
                  class="border rounded px-2" rows="4"></textarea>
              </div>
            </div>
            <div class="flex p-2 border-t">
              <a @click.prevent="start_close_diary()" class="w-full border rounded text-center bg-red-500 border-red-800 text-white mx-5" href="">
                Mbyll ditarin
              </a>
            </div>
          </div>
        </div>
      </div>
    
  </div>
</template>

<script>
  import mainSearch from '@/pages/main_search/index.vue';
  import permissionMixin from '@/mixins/permissions-mixin.js'
  // import { useProcessStore } from "@/stores/proces";
  import Datepicker from "@vuepic/vue-datepicker";
  export default {
      mixins: [permissionMixin],
      components: {
        mainSearch
      },
  }
</script>

<script setup>
import { reactive, onMounted, onBeforeUnmount , ref, inject, watch } from 'vue'
import { useRoute } from 'vue-router'
// Stores import
import { useDiaryStore } from '@/stores/diary'
import { useSearchProcess } from '@/stores/search_process'
import { useUserStore } from '@/stores/user'

import DaForm from "@/components/daform/index.vue";
import CustomerCreateForm from "@/pages/customers/forms/create.js";
import CustomerEditForm from "@/pages/customers/forms/edit.js";
import CustomersServices from "@/services/Customer";
import moment from 'moment'
import 'moment/locale/sq';
import { useRouter } from 'vue-router'
moment.locale('sq');
var numeral = require('numeral');
// import permissionsMixin from '@/mixins/permissions-mixin.js'

const toast = inject("toast");
const route = useRoute()
const router = useRouter()
// var process = reactive({
//   origin_type: 1,
//   origin: ''
// })
// ############################################################  Data
// const diaryStore = useDiaryStore()
const searchCustomerQuery = ref('')
const filteredCustomerOptions = ref([])
const selectedCustomer = ref(null)
const commentText = ref('')
// const loading = ref(false)
// Initialize max_date_today
const today = new Date();
const max_date_today = `${today.getFullYear()}` +
  `-${String(today.getMonth() + 1).padStart(2, '0')}` +
  `-${String(today.getDate()).padStart(2, '0')}`;
// Initialize form
const form = reactive({
  data: max_date_today,
  ora: '',
  perdoruesi: '',
  kanalet: '',
  pos: '',
  emri: '',
  customer: {},
  contact_nr: '',
  tipi_klientit: '',
  referuesi: 'AM',
  skema_zbritjes: '',
  adresa: '',
})
 

const customer_types = {
  'RI': 'Klient i ri',
  'ES': 'Klient ekzistues',
  'PR': 'Klient perserites',
}
const customer_form_type = ref(0)

const useSearchprocess = () => {
  return useSearchProcess()
}

const productModalMode = ref('')
// const postpone_value = ref(0)
// const countdown = ref(useDiaryStore().countdown);


// ############################################################  Methods
watch(() => useSearchprocess.search_results, (val) => {
  console.log(val)
})

// include accessory
const includeAccessory = (accessory, product) => {
  if(accessory.amount == 0) return

  accessory.included = !accessory.included
  product.accessories_included = product.accesories.filter(a => a.included).length
  // Reset accessories_total
  product.accessories_total = 0
  product.accessories_total = product.accesories.filter(a => a.included).reduce((a, b) => a + (b['price'] || 0), 0),
  useSearchProcess().search_results = [...useSearchProcess().search_results.map(p => {
    if (p.guid == product.guid) {
      return product
    }
    return p
  })]
}
// filter customer 
const filterCustomerOptions = () => {
  if (searchCustomerQuery.value === '') {
    filteredCustomerOptions.value = [...useDiaryStore().customers]
    form.location = ''
    form.contact_nr = ''
    selectedCustomer.value = null
    return
  }
  filteredCustomerOptions.value = useDiaryStore().customers.filter(option => {
    return (option.firstname + ' ' + option.lastname).toLowerCase().includes(searchCustomerQuery.value.toLowerCase()) ||
      useDiaryStore().levenshtein((option.firstname + ' ' + option.lastname).toLowerCase(), searchCustomerQuery.value.toLowerCase()) < 6 ||
      useDiaryStore().levenshtein((option.lastname + ' ' + option.firstname).toLowerCase(), searchCustomerQuery.value.toLowerCase()) < 6
  }
  )
}
const expandProduct = async (product) => {
  // collapse others if any 
  useSearchProcess().search_results = [...useSearchProcess().search_results.map(p => {
    if (p.guid != product.guid) {
      p.expanded = false
    }
    return p
  })]

  product.expanded = !product.expanded
  var prod = await useDiaryStore().expandProduct(product)
  product.accesories = [...prod.accessories.map(acc => {
    acc.is_active = true
    return acc
  })]
  useSearchProcess().search_results = [...useSearchProcess().search_results.map(p => {
    if (p.guid == product.guid) {
      return {...product}
    }
    return p
  })]
}
const addComment = () => {
  useDiaryStore().addComment({
    created_by_name: useDiaryStore().current_user.name,
    created_at: new Date(),
    text: commentText.value
  })
  commentText.value = ''
}
// focus customer search input 
const focusCustomerSearchInput = () => {
  setTimeout(() => {
    if (searchCustomerQuery.value != '') {
      if (selectedCustomer.value != null) {
        filteredCustomerOptions.value = []
      }
    }
    const input = document.querySelector('#search-customer')
    input.focus()
  }, 100)
}
// BLOCK: select customer from list
const selectOption = async (option, initial) => {
  if(option){
    console.log(option)
    searchCustomerQuery.value = option.firstname + ' ' + option.lastname
    filteredCustomerOptions.value = []
    selectedCustomer.value = option
    form.customer = option
    form.location = option.location
    form.sales_type = option.sales_type
    if (option.contacts_details) {
      form.contact_nr = ""
      // get all contacts of type phone
      var lst = option.contacts_details.filter(d => d.type_id == 1)
      lst.map((d, idx) => form.contact_nr += d.value + (idx < lst.length - 1 ? ', ' : ''))
    }
    if (option.city_name) form.location += `, ${option.city_name}`
    // check if the customer was created today 
    if (new Date(option.created_at).toDateString() == new Date().toDateString()) {
      form.tipi_klientit = 'RI'
    } else {
      form.tipi_klientit = 'ES'
    }
    if (initial) { // this will save the changes to the diary 
      var current_diary = useDiaryStore().diary?.guid
  
      // set diary customer 
      var res = await useDiaryStore().editDiary({
        customer: option,
        form: form,
        diary: useDiaryStore().diary,
        type: 'customer'
      })
  
      // will refresh the page if the diary was a new one, so not set to any customer
      if (res && res?.guid != current_diary || !route.params.id) {
        window.location.href = `/diary/get/${useDiaryStore().diary.guid}`
      }
    }
  }
}

const savePosChange = () => {
  useDiaryStore().diary.pos_name = useDiaryStore().assigned_poses.find(a => a.guid == useDiaryStore().diary.pos_id).name

  useDiaryStore().editDiary({
    form: form,
    diary: useDiaryStore().diary,
    type: 'pos'
  })
}
const updateDiary = (option) => {
  // check if have client 
  if(selectedCustomer.value.guid){
    useDiaryStore().editDiary({
      customer: option,
      form: form,
      diary: useDiaryStore().diary,
      type: 'customer'
    })
  }
}
// Create customer form
const toggleCreateCustomer = ref(false)
const createCustomer = async () => {
  toggleCreateCustomer.value = true
  customer_form_type.value = 0
  useDiaryStore().customer_create_form = { loading: true }
  useDiaryStore().customer_create_form = await CustomerCreateForm(false)
}
// Edit customer form 
const editCustomer = async () => {
  toggleCreateCustomer.value = true
  customer_form_type.value = 1
  useDiaryStore().customer_create_form = { loading: true }
  useDiaryStore().customer_create_form = await CustomerEditForm(selectedCustomer.value)
}
// Save customer and set new customer as current customer
const submitCustomer = async (form) => {
  if (customer_form_type.value == 0) {
    var new_customer = await CustomersServices.createCustomer(form)
    useDiaryStore().customers.push(new_customer)
    selectOption(new_customer, true)
  } else {
    form.guid = selectedCustomer.value.guid
    var customer = await CustomersServices.updateCustomer(form)
    useDiaryStore().customers = [...useDiaryStore().customers.map(c => {
      if (c.guid == customer.guid){
        return customer
      }
      return c
    })]
    selectOption(customer)
  }
  toggleCreateCustomer.value = false
}

// Reset customer 
const resetCustomer = () => {
  if (selectedCustomer.value != null) {
    if (searchCustomerQuery.value != selectedCustomer.value.name) {
      searchCustomerQuery.value = selectedCustomer.value.name
    }
  }
}
const outOfCustomersList = () => {
  // if (searchCustomerQuery.value != '') {
  //   if (selectedCustomer.value != null) {
  //     filteredCustomerOptions.value = []
  //   }
  // }
}

// const toggleAddProduct = ref(false)
const toggleProductList = ref(false)

const openProductList = async (mode) => {
  toggleProductList.value = true
  productModalMode.value = mode

  if(mode == 'process'){
    // Get processes list with origin_type = 1 (pos)
    await useDiaryStore().get_desk_processes()
  }

}

const add_diary_product_start = async (result, diary_guid) => {

  var res = await useDiaryStore().add_diary_product(result, diary_guid)

  if (res.toast === 'success') {
      toast.success('Produkti u shtua me sukses.');
      // useDiaryStore().toggleAddProduct = false;
    }

}

const open_prev_diary = (id) => {
  router.push({ name: 'Ditar Shiko', params: { id } })
}

// Request discount
// const checkDiaryProduct = (product) => {
//   useDiaryStore().products = useDiaryStore().products.map(p => {
//     if(product.guid == p.guid){
//       if(p.is_active){
//         p.is_active = false
//       }
//       else {
//         p.is_active = true
//       }
//     }
//     return p
//   })
// }
const start_request_discount = () => {
  if(useDiaryStore().selected_for_discount.length == 0) return
  useDiaryStore().request_disctount()
}
const start_employee_discount_request = () => {
  if(useDiaryStore().selected_for_discount.length == 0) return
  // TODO: Check if employee can give discount
  // TODOL Ckeck if the employee have already given the discount 
  useDiaryStore().employee_request_discount()
}

const start_select_desk_process = () => {
  useDiaryStore().select_desk_process()
}

const start_remove_diary_product = async (product) => {
  var res = await useDiaryStore().remove_diary_product(product)

  if(res && res.toast === 'success'){
    toast.success('Produkti u hoq nga lista.')
  }
}

const start_process_desk = async () => {
  if(!useDiaryStore().is_expired(useDiaryStore().diary?.valid_until_date)){
    if(useDiaryStore().selected_for_process.length == 0){
      toast.error('Zgjidh nje ose disa nga produktet e mesiperme.')
    } 
    else if(!useDiaryStore().selected_desk_process){
      toast.error('Zgjidh procesin.')
    }
    else {
      var res = await useDiaryStore().process_to_desk()
      if(res.toast === 'success'){
        toast.success('Produkti u hoq nga lista.')
      }
    }
  }
  else {
    toast.error('Ky ditar ka skaduar.')
  }
}

const start_postpone_diary = async() => {
  if(!useDiaryStore().diary.employee_requested_text_new || !useDiaryStore().postpone_value){
    toast.error('Zgjidhni kohen dhe jepni nje arsye per shtimin e kohes.')
    return 
  }
  var res = await useDiaryStore().postpone_diary()
  if(res.toast === 'success'){
    toast.success('Koha u shtua me sukses.')
  }
}

const start_toggle_diary_product_active = async(product) => {
  var res = await useDiaryStore().toggle_diary_product_active(product)
  if(res.toast === 'show'){
    if(res.is_active){
      toast.success('Produkti u be aktiv.');
    }
    else {
      toast.success('Produkti u be jo aktiv.');
    }
  } else if(res.toast === 'request_error'){
      toast.error('Something went wrong.');
  }
}

const formatDate = (date) => {
  return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:ss");
}

const open_discount_template = (request_id) => {
  router.push({ name: 'Konfirmo zbritje', params: { id: request_id } })
}

const start_create_free_line = async () => {
  var a = await useDiaryStore().create_free_line()

  if(a == 'success'){
    toast.success('Produkti u shtua me sukses.');
    useDiaryStore().toggleAddProduct = false;
  }
}
const start_close_diary = async (status) => {
  if(!useDiaryStore().diary.cancel_status){
    toast.error('Ju lutem zgjidhni statusin.');
    return
  }
  if(!useDiaryStore().diary.reject_reason){
    toast.error('Ju lutem zgjidhni arsyen e mbylljes se ditarit.');
    return
  }
  var a = await useDiaryStore().close_diary(useDiaryStore().diary.cancel_status)
  if(a == 'deleted_success'){
    router.push({ name: 'Ditar te Hapur', path: '/diary/list' })
    toast.success('Ditari u mbyll me sukses.');
  }
}

const add_diary_service_start = async (service) => {
  var a = await useDiaryStore().add_diary_service(service)
  if(a == 'success'){
    toast.success('Sherbimi u shtua me sukses!');
  }
}

const start_toggle_service_modal = (type, service) => {
  if(useDiaryStore().diary?.status_id == 7 || useDiaryStore().diary?.status_id == 5) return
  useDiaryStore().toggle_service_modal(type, service)
}

const start_toggle_pickup_modal = (product) => {
  if(useDiaryStore().diary?.status_id == 7 || useDiaryStore().diary?.status_id == 5) return
  useDiaryStore().toggle_pickup_modal(product)
}

const start_save_pickup_location = async (product) => {
  var a = await useDiaryStore().save_pickup_location()
  if(a.message == 'success'){
    toast.success('Dorezimi u ruajt me sukses.')
  }
}
const start_delete_pickup = async (product) => {
  var a = await useDiaryStore().delete_product_pickup(product)
  if(a.message == 'success'){
    toast.success('Dorezimi u hoq nga produkti.')
  }
}

const start_save_item_amount = async (product) => {
  var a = await useDiaryStore().save_item_amount(product)
  if(a.message == 'success'){
    toast.success('Sasia u ndryshua me sukses.')
  }
}


const start_postpone_request = (product) => {
  if(!useDiaryStore().diary.requested_by_customer_new || useDiaryStore().loading_postpone) return
  else{
    var aa = useDiaryStore().save_postpone_request()
    if(aa.message == 'success') toast.success('Ditari u kalua ne pritje.')
    if(aa.message == 'error') toast.error('Dicka nuk funksionoi.')
  }
}

const start_edit_customer_waiting = async (product) => {
  useDiaryStore().diary.requested_by_customer_new = useDiaryStore().diary.requested_by_customer
  useDiaryStore().diary.requested_text_new = useDiaryStore().diary.requested_text
  useDiaryStore().customer_waiting_edit_mode = true
}



  onMounted(async () => {
    useDiaryStore().loading = true
    // get poses of user 
    await useDiaryStore().getAssignedPoses()
    // assign the first one as default
    if (useDiaryStore().assigned_poses.length > 0) {
      form.pos = useDiaryStore().assigned_poses[0].guid
      if (route.params.id) {
        // get diary 
        var diary = await useDiaryStore().getDiary(route.params.id)
        if(diary) form.pos = diary.pos_id;

        useDiaryStore().startCountdown();
        // // get diary products 
        // await useDiaryStore().getDiaryProducts(route.params.id)
        // // get diary comments 
        // await useDiaryStore().getDiaryComments(route.params.id)
        // // get diary customer 
        selectedCustomer.value = diary.customer
        useDiaryStore().process.origin = diary.pos_id
        selectOption(diary.customer)
        if (diary && diary.contact?.length > 0 && diary?.contact[0]) {
          form.contact_nr = diary.contact[0].phone 
          form.location = diary.contact[0].address
        }

      }
      // create empty diary and set it as current diary 
      else { 
        useDiaryStore().diary = {}
        // get customers 
        await useDiaryStore().getCustomers()
        if(route.params.product_id){
          form.product_id = route.params.product_id
          form.warehouse_id = route.params.warehouse_id
          form.pos_id = route.params.pos_id
          // form.customer = 
          // console.log('from search', form)
          return
        }
        else{
          var ndiary = await useDiaryStore().createDiary(form)
          useDiaryStore().process.origin = ndiary.pos_id
        }
      }
    } else {
      // customer is not allowed to any point of sale to create diary 
      toast.error('Nuk keni akses ne asnje pike shitjeje')

      setTimeout(() => {
        window.location.href = '/'
      }, 4000)
    }
    // get loan institutions 
    await useDiaryStore().getLoanInstitutions()

    // get customers 
    await useDiaryStore().getCustomers()

    // Get trasnport services
    await useDiaryStore().get_services()

    useDiaryStore().loading = false
    // Tab title
    document.title = 'Ditar ' + useDiaryStore().diary.status + ': ' + (useDiaryStore().diary.customer_name ?? 'Krijo')
  })
  
  // Stop the countdown when the component is destroyed
  onBeforeUnmount(() => {
    useDiaryStore().stopCountdown();
  });
</script>




<style scoped>
  .chat-container-left {
    position: relative;
  }
  .chat-container-left::before {
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 0 10px;
    border-color: rgba(161 185 223) transparent transparent transparent;
  }
  
  .chat-container-right {
    position: relative;
  }
  .chat-container-right::before {
    content: '';
    position: absolute;
    top: 0;
    right: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 0;
    border-color: rgba(161 185 223) transparent transparent transparent;
  }
  .faded-header > th{
    font-size:10px;
    color:rgb(148, 163, 184);

  }
  .datepicker-wrap-pickup > .dp__input{
    padding:0px!important;
  }
  @import './style.css'
</style>

